import React, { useState, Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Axios from "../../libs/axios/Axios";
import PhotoSwiper from "../../libs/swiper/Swiper";

function SharedUrlPreview() {
  const [slideARR, setSlideARR] = useState([]);
  const [toggleModal, setToggleModal] = useState(true);

  const [searchParams] = useSearchParams();
  const metaId = searchParams.get("metaId");
  const type = searchParams.get("type");

  useEffect(() => API_GetSharedPhotos(), []);

  function API_GetSharedPhotos() {
    const pathname = "/api/select/shared/url/preview";
    const query = `?metaId=${metaId}&type=${type}`;
    const endpoint = pathname + query;

    Axios({
      method: "GET",
      type: "json",
      url: endpoint,
    })
      .then((res) => {
        if (res.data.length) setSlideARR(res.data);
      })
      .catch((err) => console.error(err));
  }

  return (
    <Fragment>
      {slideARR.length && (
        <PhotoSwiper
          files={slideARR}
          index={0}
          closeModal={() => setToggleModal(false)}
          showModal={toggleModal}
        />
      )}
    </Fragment>
  );
}

export default SharedUrlPreview;
