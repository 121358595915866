import React, { useState, useEffect } from "react";
import Axios from "../../libs/axios/Axios";
import "./Auth.css";

import { Box, Content } from "../../components/layout/Container/Container";

import { Button } from "../../components/inputs/buttons/Buttons";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgError, setLoginError] = useState("");

  useEffect(() => {
    document.title = "Xfa Circle - Login";
  }, []);

  const HandleLogin = () => {
    Axios({
      method: "POST",
      type: "JSON",
      url: "/api/admin/login",
      data: { email: email.trim(), password: password.trim() },
    })
      .then((res) => {
        res.data.status
          ? (window.location.href = res.data.redirectURL)
          : setLoginError(res.data.message);
      })
      .catch((err) => console.error(err));
  };

  function HandleKeyDown(e) {
    if (e.keyCode === 13) HandleLogin();
  }

  return (
    <Content className="auth_content FULL_WIDTH">
      <Box className="article_signup SMALL_WIDTH">
        <Box className="con_signup-content">
          <Box className="box_form-header">
            <h1 className="form-heading">Admin Login</h1>
            <p style={{ color: "lightGrey", marginBottom: "1rem" }}>
              Restricted Access
            </p>
            <span className="login-error-msg" style={{ color: "red" }}>
              {msgError}
            </span>
          </Box>

          <input
            type="email"
            name="email"
            value={email}
            placeholder="Email Address*"
            autoFocus
            required
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => HandleKeyDown(e)}
          />

          <input
            type="password"
            name="password"
            value={password}
            placeholder="Password*"
            required
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => HandleKeyDown(e)}
          />

          <Button
            style={{ marginTop: "20px" }}
            size={"Medium"}
            position={"Center"}
            variant={"Filled"}
            value={"Log In"}
            onclick={() => HandleLogin()}
          />

          <a
            href="/forgotPassword"
            style={{
              textAlign: "center",
              marginTop: "1rem",
              color: "#1368ce",
            }}
          >
            Forgot Password
          </a>
        </Box>
      </Box>
    </Content>
  );
}

export default Login;
