import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Axios from "../../../libs/axios/Axios";
import { Content } from "../../../components/layout/Container/Container";
import { Button } from "../../../components/inputs/buttons/Buttons";
import "./Password.css";

function ResetPassword() {
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const navigateTo = useNavigate();

  useEffect(() => API_CheckIfTokenIsValid(), []);

  function API_CheckIfTokenIsValid() {
    const url = "/api/select/password/token/validation?";
    const query = `adminId=${searchParams.get("id")}&token=${searchParams.get(
      "token"
    )}`;
    const endpoint = url + query;

    Axios({
      method: "GET",
      type: "json",
      url: endpoint,
    }).then((res) => {
      if (!res.data.status) {
        setIsLinkExpired(false);
        setMessage(res.data.message);
      }

      if (res.data.status) {
        setIsLinkExpired(true);
      }
    });
  }

  function API_ResetPassword(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/update/password/reset/admin",
      data: data,
    }).then((res) => {
      if (!res.data.status) setMessage(res.data.message);
      if (res.data.status) navigateTo("/admin");
    });
  }

  function HandleResetPasswordSumbit() {
    let data = {
      adminId: searchParams.get("id"),
      email: searchParams.get("email"),
      password: password,
    };

    const obj = Object.values(data).every((value) => !!value);
    if (!obj) {
      alert("Enter your new password.");
      return;
    }

    if (obj) {
      if (password !== confirmPassword) {
        alert("Password and confirm password does not match.");
        return;
      } else {
        API_ResetPassword(data);
      }
    }
  }

  return (
    <Content>
      <div className="reset-pwd_content">
        <div className="reset-pwd_heading_con">
          <h1>Reset Admin Password</h1>
          <h2>Enter new password for {searchParams.get("email")}</h2>

          {!isLinkExpired && (
            <>
              <span
                style={{
                  marginTop: "2rem",
                  color: "white",
                  padding: "0.5rem 1rem 0.5rem 1rem",
                  borderRadius: "5px",
                  background: "red",
                }}
              >
                {message}
              </span>

              <a
                href="/forgotPassword"
                style={{ marginTop: "2rem", color: "blue", cursor: "pointer" }}
              >
                Forgot Password
              </a>
            </>
          )}
        </div>

        {isLinkExpired && (
          <>
            <div className="reset-pwd_inp_con">
              <label>New Password</label>
              <input
                type="password"
                value={password}
                autoFocus={true}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="reset-pwd_inp_con">
              <label>Confirm Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <Button
              value={"Reset Password"}
              variant={"Filled"}
              style={{ marginTop: "2rem" }}
              onclick={() => HandleResetPasswordSumbit()}
            />
          </>
        )}
      </div>
    </Content>
  );
}

export default ResetPassword;
