import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../libs/axios/Axios";
import {
  RiHome2Line,
  RiHome2Fill,
  RiImageLine,
  RiImageFill,
} from "react-icons/ri";
import {
  MdOutlineAdminPanelSettings,
  MdAdminPanelSettings,
} from "react-icons/md";
import Logout from "../../pages/auth/Logout";
import "../header/Header.css";
import user from "../../assets/images/user_48.png";

function Header({ isAuth }) {
  const [isDropdown, setDropdown] = useState(false);
  const [adminPhoto, setAdminPhoto] = useState(user);
  const urlPathname = window.location.pathname;

  const navigateTo = useNavigate();

  useEffect(() => {
    if (isAuth) API_SelectAdminPhoto();
  }, [isAuth]);

  function API_SelectAdminPhoto() {
    Axios({
      method: "GET",
      type: "json",
      url: "/api/select/admin/photo",
    })
      .then((res) => {
        setAdminPhoto(res.data._adminPhoto);
      })
      .catch((err) => console.error(err));
  }

  return (
    <header>
      <nav>
        <a className="logo" href="/">
          <span className="logo-label">XFA CIRCLE</span>
        </a>

        <ul className="ul_navbar">
          <li className="menu" onClick={() => navigateTo("/")}>
            {urlPathname === "/" ? (
              <>
                <RiHome2Fill style={{ fill: "#1368ce" }} className="nav_icon" />
                <span className="nav-labels" style={{ color: "#1368ce" }}>
                  Feed
                </span>
              </>
            ) : (
              <>
                <RiHome2Line className="nav_icon" />
                <span className="nav-labels">Feed</span>
              </>
            )}
          </li>

          <li className="menu" onClick={() => navigateTo("/photos")}>
            {urlPathname === "/photos" ? (
              <>
                <RiImageFill style={{ fill: "#1368ce" }} className="nav_icon" />
                <span className="nav-labels" style={{ color: "#1368ce" }}>
                  Photos
                </span>
              </>
            ) : (
              <>
                <RiImageLine className="nav_icon" />
                <span className="nav-labels">Photos</span>
              </>
            )}
          </li>

          {!isAuth && (
            <li className="menu" onClick={() => navigateTo("/admin")}>
              {urlPathname === "/admin" ? (
                <>
                  <MdAdminPanelSettings
                    style={{ fill: "#1368ce" }}
                    className="nav_icon"
                  />
                  <span className="nav-labels" style={{ color: "#1368ce" }}>
                    Admin
                  </span>
                </>
              ) : (
                <>
                  <MdOutlineAdminPanelSettings className="nav_icon" />
                  <span className="nav-labels">Admin</span>
                </>
              )}
            </li>
          )}

          {isAuth && (
            <li className="menu" onClick={() => setDropdown((show) => !show)}>
              <img
                src={adminPhoto}
                alt="admin"
                style={{ borderRadius: "50%" }}
              />
              {isDropdown && (
                <ul className="ul_menu-dropdown">
                  <li onClick={() => navigateTo("/dashboard/")}>Dashboard</li>
                  <li onClick={() => Logout()}>Logout</li>
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
