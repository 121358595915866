import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MdTravelExplore } from "react-icons/md";
import { HashLink as Link } from "react-router-hash-link";
import "./Adventure.css";

import mackenzieFalls from "./images/mackenzie-falls.jpg";
import trenthamFall from "./images/trentham-falls.jpg";
import pyramidRocks from "./images/pyramid-rocks.jpg";
import thousandSteps from "./images/1000-steps.jpg";
import treeTop from "./images/tree-top.jpg";
import youYangs from "./images/you-yangs.jpg";
import hangingRock from "./images/hanging-rock.jpg";
import gibsonSteps from "./images/gibson-steps.jpg";
import lerderdergPark from "./images/lerderderg-park.jpg";
import organPipes from "./images/organ-pipes.jpg";
import werribeeGorge from "./images/werribee-gorge.jpg";
import hopetounFall from "./images/hopetoun-fall.jpg";
import masonsFall from "./images/masons-fall.jpg";
import capeSchanckTrail from "./images/cape-schanck.jpg";
import noojeeTrestleBridge from "./images/nooje-bridge.jpg";
import redwoodsForest from "./images/redwood-forest.jpg";
import sailorsFall from "./images/sailors-fall.jpg";
import toorongoFall from "./images/toorongo-fall.jpg";
import buckleyFall from "./images/buckley-fall.jpg";
import erskineFall from "./images/erskine-fall.jpg";
import georgeBassCoastal from "./images/george-bass-coastal.jpg";
import happyValleyTrack from "./images/happy-valley-track.jpg";
import burkesLookout from "./images/burkes-lookout.jpg";
import bridgewaterBay from "./images/bridgewater-bay.jpg";
import pointNepeanWalk from "./images/point-nepean-national-park.jpg";

const destinationArr = [
  {
    id: "01",
    title: "MacKenzie Falls",
    location: "Grampians National Park",
    summary: `Stunning waterfall including some of the beautiful bush scenery and a steep walk down to the base of the falls for a closer view. You can also watch the majestic waterfall from the lookout platform providing breathtaking views from a different angle or challenge yourself to take the steps down and up. Warning, it is pretty steep.`,
    duration: "3hrs",
    distance: "1hr / 2km",
    difficulty: "Medium to hard",
    image: mackenzieFalls,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=84",
  },

  {
    id: "02",
    title: "Trentham Falls",
    location: "Coliban River Scenic Reserve",
    summary: `The Trentham Falls is great and has lots of water. You can view the fall from the lookout and even though there is a clear sign 'no access' many ignore the sign & continue to walk down but be careful walking on it. The area around the waterfall is stunning and worth visiting.`,
    duration: "1.30hrs",
    distance: "1-2hrs / 6km",
    difficulty: "Easy to medium",
    image: trenthamFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=105",
  },

  {
    id: "03",
    title: "Pyramid Rock",
    location: "The Nobbies and Pyramid Rock",
    summary: `A short and easy route will lead you to Pyramid Rock, which peaks up from the sea, with incredible views of the coastline on each side. The boardwalk to the overlook was well maintained and in excellent condition, making for a lovely and simple walk - a visit to this location is highly recommended.`,
    duration: "2hrs",
    distance: "10-15mins / 800m",
    difficulty: "Easy to medium",
    image: pyramidRocks,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=149",
  },

  {
    id: "04",
    title: "1000 Steps Walk Trail",
    location: "Dandenong Ranges National Park",
    summary: `Visitors can take in breath-taking views of the surrounding woodland and rich nature throughout the route. The trail offers a variety of walking alternatives, including shorter routes for beginners, making it suited for walkers of all fitness levels. Anyone looking for a pleasant and enjoyable strolling experience in Victoria's stunning natural setting must check out the 1000 Steps Walk Trail through the trees.`,
    duration: "1hr",
    distance: "1hr / 1.8km",
    difficulty: "Medium to hard",
    image: thousandSteps,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=112",
  },

  {
    id: "05",
    title: "Otway Fly Treetop Adventures",
    location: "Great Otway National Park",
    summary: `Beautiful scenery, extremely relaxing stroll in the treetops then up into the canopy. It's a nice area to visit with the family and little children may stroll there easily. The forest is well maintained with beautiful ferns to admire along the way.`,
    duration: "2.40hrs",
    distance: "15-30mins / 1.2km",
    difficulty: "Easy to medium",
    image: treeTop,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=115",
  },

  {
    id: "06",
    title: "You Yangs",
    location: "Little River, Victoria",
    summary: `Beautiful You Yangs is a place that welcomes walkers and hikers of all abilities. a beautiful park with a variety of walking routes. The paths are clearly marked and well-maintained. It's a lovely location to stroll around in and relax.`,
    duration: "1.15hrs",
    distance: "2-3hrs / 12.3km",
    difficulty: "Medium to hard",
    image: youYangs,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=118",
  },

  {
    id: "07",
    title: "Hanging Rock",
    location: "Newham, Victoria",
    summary: `The rock formation is an impressive sight to behold with an amazing panoramic view. The ascent to the rock typically takes about 20-30 minutes, varying based on your physical fitness. Beautiful views from the very top and a unique environment with an iconic atmosphere.`,
    duration: "1.15hrs",
    distance: "1-2hrs / 4km",
    difficulty: "Medium to hard",
    image: hangingRock,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=108",
  },

  {
    id: "08",
    title: "Gibson Steps",
    location: "Port Campbell National Park",
    summary: `This stop along the Great Ocean Road is a must see for anyone who appreciates natural beauty and stunning views. While the hike down to the beach may be a bit challenging, the effort is well worth it for the experience you'll have once you're there. You won't be disappointed!`,
    duration: "3hrs",
    distance: "1-2hrs / 4km",
    difficulty: "Medium to hard",
    image: gibsonSteps,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=125",
  },

  {
    id: "09",
    title: "Lerderderg Park",
    location: "Lerderderg National Park",
    summary: `Whether you're looking for a challenging hike or a peaceful picnic spot, the Lerderderg park has something to offer everyone. The stunning gorge that's carved into the landscape, and the cliffs rise on either side of the river that cannot be missed!`,
    duration: "1.30hrs",
    distance: "2-3hrs / 13.5km",
    difficulty: "Medium to hard",
    image: lerderdergPark,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=111",
  },

  {
    id: "10",
    title: "Organ Pipes",
    location: "Organ Pipes National Park",
    summary: `The Organ Pipes National Park is a must-visit vacation spot for nature lovers and hikers. The towering basalt columns resemble a giant pipe organ, offering a unique sight that cannot be located anywhere else in the world!`,
    duration: "1.30hrs",
    distance: "1hr / 3km",
    difficulty: "Medium to hard",
    image: organPipes,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=133",
  },

  {
    id: "11",
    title: "Werribee Gorge",
    location: "Werribee Gorge State Park",
    summary: `Werribee Gorge State Park provides stunning views of the gorge, which is carved into the landscape through the Werribee River. The park is well-maintained and offers a variety of hiking trails, suitable for hikers of all levels!`,
    duration: "1.15hrs",
    distance: "1-2hrs / 5km",
    difficulty: "Medium to hard",
    image: werribeeGorge,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=119",
  },

  {
    id: "12",
    title: "Hopetoun Fall",
    location: "Great Otway National Park",
    summary: `Hopetoun Falls is a wonderful waterfall is located just a quick drive from the town of Beech Forest. There is additionally a steep walking track that leads down to the base of the falls however just be sure to put on sturdy shoes and exercise caution, as the rocks can be slippery.`,
    duration: "2.45hrs",
    distance: "1-2hrs / 3.5km",
    difficulty: "Medium to hard",
    image: hopetounFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=122",
  },

  {
    id: "13",
    title: "Masons Falls",
    location: "Kinglake National Park",
    summary: `With a drop of around 20 meters, Mason Falls is a stunning natural wonder that cascades into a beautiful rock pool below. You can enjoy a leisurely walk along the easy walking track that leads to the viewing platform which is particularly stunning during the autumn season when the leaves on the trees change colours.`,
    duration: "1.30hrs",
    distance: "1-2hrs / 4.6km",
    difficulty: "Easy to medium",
    image: masonsFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=107",
  },

  {
    id: "14",
    title: "Cape Schanck Walking Trail",
    location: "Mornington Peninsula National Park",
    summary: `The boardwalk provided an amazing view of the ocean and was a pleasure to walk on because of its excellent construction. We began by walking for a while before heading up the hill to see the lighthouse. The hike was challenging, but the incredible view from the summit made it all worthwhile. It's a peaceful and lovely setting ideal for a leisurely stroll.`,
    duration: "1.25hrs",
    distance: "1-2hrs / 6km",
    difficulty: "Easy to medium",
    image: capeSchanckTrail,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=110",
  },

  {
    id: "15",
    title: "Noojee Trestle Bridge Walk",
    location: "Noojee Trestle Bridge Rail Trail",
    summary: `Nooje Bridge is a testament to the region’s rich history. The bridge's rusted iron frame and wooden planks provide a unique and memorable backdrop for visitors and photographers alike. There are also several walking tracks that lead from the bridge into the nearby forest if you want to explore the area further.`,
    duration: "1.40hrs",
    distance: "1hr / 3km",
    difficulty: "Easy to medium",
    image: noojeeTrestleBridge,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=117",
  },

  {
    id: "16",
    title: "Redwoods Forest",
    location: "Yarra Ranges National Park",
    summary: `The Redwood Forest is home to some of the tallest trees in the world with some trees reaching over 60 meters tall. There several hiking trails that lead deeper into the forest to explore the beauty of the forest up close, with many hidden beauties waiting to be discovered along the way.`,
    duration: "1.40hrs",
    distance: "30mins / 1.6km",
    difficulty: "Medium to hard",
    image: redwoodsForest,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=120",
  },

  {
    id: "17",
    title: "Sailors Falls",
    location: "Hepburn Regional Park",
    summary: `Sailors Falls Mineral Spring is in the Wombat State Forest close to the town of Daylesford. The falls is in the height of the main road, so to get the main view you can walk down the stairs to the base of the waterfall. Best to go after a heavy rainfall so the waterfall is flowing to its maximum.`,
    duration: "1.30hrs",
    distance: "1-2hrs / 4km",
    difficulty: "Easy to medium",
    image: sailorsFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=123",
  },

  {
    id: "18",
    title: "Toorongo Falls",
    location: "Toorongo Falls Reserve",
    summary: `The ferns at Toorongo Falls are quite tall, and the climb there is not too difficult. Families with kids or casual hikers will love it here. The walk takes around an hour and includes some stairwells and steep sections, but the effort is well when you reach the top.`,
    duration: "1.50hrs",
    distance: "1-2hrs / 2.5km",
    difficulty: "Easy to medium",
    image: toorongoFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=124",
  },

  {
    id: "19",
    title: "Buckley Falls",
    location: "Buckley Falls Reserve",
    summary: `At all levels can experience the quick and easy walking path at Buckley Falls. The bulk of the path is easy to follow and gives beautiful views of the falls, while sure sections of it may be rough and rocky. You can even take the short bridge to cross the river and access the paper mill home by climbing it.`,
    duration: "1hr",
    distance: "1-2hrs / 4.7km",
    difficulty: "Easy to medium",
    image: buckleyFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=127",
  },

  {
    id: "20",
    title: "Erskine Falls",
    location: "Great Otway National Park",
    summary: `You can view the falls as they flow in three stages down a sheer cliff from the top viewing deck. There is a deep pool of water at the bottom of the falls where visitors can swim and relax. The fall is simple and well-maintained. Be careful that the location has poor mobile reception.`,
    duration: "3hrs",
    distance: "2-3hrs / 7.5km",
    difficulty: "Easy to medium",
    image: erskineFall,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=128",
  },

  {
    id: "21",
    title: "George Bass Coastal Walk",
    location: "San Remo, Victoria",
    summary: `I suggest avoiding warmer days because the terrain is fairly challenging and can get slippery and puddle-filled after rain, and I also advise having quality waterproof shoes. Despite the walk being one of the trickiest, it is well worth the trip thanks to several fascinating tunnels among coastal shrubs, as well as the expansive views of Bass Strait.`,
    duration: "1.40hrs",
    distance: "3-4hrs / 14km",
    difficulty: "Easy to medium",
    image: georgeBassCoastal,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=129",
  },

  {
    id: "22",
    title: "Happy Valley Track",
    location: "Long Forest Nature Conservation Reserve",
    summary: `The Happy Valley Track in Long Forest is a fantastic hiking track that combines challenging terrain with beautiful scenery. Although the path is clearly marked with signs, certain sections might be difficult to follow, therefore it's crucial to pay attention to the markings. Although there are a few challenging climbs and excessive stretches, the stunning views along the way make it all worthwhile.`,
    duration: "1hr",
    distance: "1-2hrs / 3.5km",
    difficulty: "Easy to medium",
    image: happyValleyTrack,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=130",
  },

  {
    id: "23",
    title: "Burkes Lookout",
    location: "Mount Dandenong",
    summary: `The summit's view is stunning despite the challenging hike's rough terrain, making it a great day trip location. Start from Glasgow Road if you're up for a lengthy hike; the panoramic view from the top of Melbourne's skyline is the reward that makes the effort worthwhile.`,
    duration: "1hr",
    distance: "1hr / 3.5km",
    difficulty: "Medium to hard",
    image: burkesLookout,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=132",
  },

  {
    id: "24",
    title: "Bridgewater Bay",
    location: "Mornington Peninsula National Park",
    summary: `An excellent day trip destination is Bridgewater Bay in Mornington Peninsula National Park. Even though it's not a particularly long walk, you should be ready to climb some stairs. The beach provides amazing ocean views while being tucked away among beautiful rocky cliffs. Make sure to allow enough time to explore the stunning rock pools in the area.`,
    duration: "1.30hr",
    distance: "1hr / 2.2km",
    difficulty: "Easy to medium",
    image: bridgewaterBay,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=143",
  },

  {
    id: "25",
    title: "Point Nepean Walk",
    location: "Point Nepean National Park",
    summary: `The National Park boasts a range of recreational activities that cater to everyone. You can have interaction in walking, bike riding, picnicking, or simply soaking in the sun on the beach. The strolling tracks are relatively easy, allowing you to set your personal pace and fully immerse yourself in the park's beauty. With its rich historical significance, Point Nepean National Park provides many opportunities for exploration. The walking trails cater to all fitness levels, making it accessible for everyone.`,
    duration: "1.45hr",
    distance: "1hr / 3.5km",
    difficulty: "Easy to medium",
    image: pointNepeanWalk,
    photoSlide: "https://xfacircle.app/shared/preview?type=alb&metaId=148",
  },
];

function DestinationCard({
  id,
  image,
  title,
  location,
  summary,
  duration,
  distance,
  difficulty,
  onclick,
}) {
  return (
    <div id={id} className="adv_place_card">
      <div className="adv_place-img_con">
        <LazyLoadImage
          className="adv_place-img_con"
          alt={image}
          height={image}
          src={image}
          width={image}
        />

        <button
          type="button"
          className="adv_place-more-photos_btn"
          onClick={() => {
            onclick();
          }}
        >
          More Photos
        </button>
      </div>

      <h2 className="adv_place-title">{title}</h2>
      <h3 className="adv_place-location">{location}</h3>
      <p className="adv_place-summary">{summary}</p>

      <div className="adv_place-meta_con">
        <span>
          <p>Drive time from CBD:</p>
          <p>{duration}</p>
        </span>

        <span>
          <p>Duration:</p>
          <p>{distance}</p>
        </span>

        <span>
          <p>Level of difficulty:</p>
          <p>{difficulty}</p>
        </span>
      </div>
    </div>
  );
}

function Adventure() {
  const [isDestinationListShown, setIsDestinationListShown] = useState(false);
  const [sortedDesArr, setSortedDesArr] = useState([]);

  useEffect(() => {
    HandleSort();
  }, []);

  function HandleSort() {
    const sortedData = [...destinationArr].sort((a, b) => {
      return a.title > b.title ? 1 : -1;
    });
    setSortedDesArr(sortedData);
  }

  return (
    <div>
      <div className="adv_banner_con">
        <div className="adv_banner-content_con">
          <div className="adv_banner-heading_con">
            <h1 className="adv_banner_heading">
              Our Favourite Hikes Near Melbourne
            </h1>
          </div>
        </div>
      </div>

      <div className="adv_content">
        <p>
          Welcome to our family adventure blog! We have been exploring the great
          outdoors for many years and have had some amazing experiences along
          the way. From scaling mountains to navigating through dense forests,
          we have challenged ourselves both mentally and physically.
          <br />
          <br />
          Our blog will share our hiking and adventure experiences, as well as
          ideas and recommendations for those interested in starting their own
          adventures! <br />
        </p>

        {/* --- 📌 List of Places ↓ */}

        {sortedDesArr.length &&
          sortedDesArr.map((a) => {
            return (
              <DestinationCard
                key={a.id}
                id={a.id}
                image={a.image}
                title={a.title}
                location={a.location}
                summary={a.summary}
                duration={a.duration}
                distance={a.distance}
                difficulty={a.difficulty}
                onclick={() => window.open(a.photoSlide, "_blank")}
              />
            );
          })}
      </div>

      <div className="adv_destination-menu_con">
        <MdTravelExplore
          className="explore-icon"
          onClick={() => setIsDestinationListShown((show) => !show)}
        />

        {isDestinationListShown && (
          <ul className="adv_destination-menu_ul">
            {sortedDesArr.map((a) => {
              return (
                <li key={a.id}>
                  <Link
                    scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    to={"#" + a.id}
                    className="adv_destination-menu_a"
                  >
                    {a.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Adventure;
