import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { RiCloseFill } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../swiper/Swiper.css";

import { ElapsedTime } from "../../utils/DateTime";
import { TextWithLineBreaksReadMore } from "../../components/layout/views/Text";

function PhotoSwiper({ files, index, showModal, closeModal }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isMobile, setIsMobile] = useState(null);
  const [pathname, setPathname] = useState("");

  const navigateTo = useNavigate();

  useEffect(() => setPathname(window.location.pathname), []);

  useEffect(() => {
    HandleBrowserSizeOnLoad();
    HandleBrowserOnResize();
  }, []);

  useEffect(() => {
    if (showModal) document.body.style.overflow = "hidden";
    if (!showModal) document.body.style.overflow = "unset";
  }, [showModal]);

  function HandleBrowserOnResize() {
    window.onresize = function () {
      let widthWin = window.document.body.clientWidth;
      if (widthWin <= 800) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  }

  function HandleBrowserSizeOnLoad() {
    let widthWin = window.document.body.clientWidth;
    if (widthWin <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  return showModal ? (
    <div className="SwiperModal">
      <div
        className="SwiperGrid"
        style={{ gridTemplateColumns: !files[0]._adminPhoto && "100%" }}
      >
        <div className="SwiperHeaderBox">
          <span>Media</span>
          <RiCloseFill
            className="SwiperCloseModal"
            onClick={() => {
              setThumbsSwiper(null);
              if (pathname === "/shared/preview") navigateTo("/");
              closeModal();
            }}
          />
        </div>

        <div className="SwiperSlideBox">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mainSwiper"
            initialSlide={Number(index)}
          >
            {files[0]._files.map((a, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={a._baseURL} alt={a._filename} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="thumbSwiper"
          >
            {files[0]._files.map((a, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={a._baseURL} alt={a._filename} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <aside
          className="SwiperRightSidebar"
          style={{
            display: !files[0]._adminPhoto && "none",
            background: isMobile ? "rgba(0,0,0,0.6)" : "#fff",
          }}
        >
          <div className="SwiperProfileBox">
            <div className="SwiperProfileHeader">
              <div className="SwiperProfileImgBox">
                <img src={files[0]._adminPhoto} />
              </div>
              <div className="SwiperProfileDetailBox">
                <span className="FullName">{files[0]._enteredBy}</span>
                {files[0]._title && files[0]._title.length && (
                  <span className="Title">{files[0]._title}</span>
                )}
                <span className="DateCreated">
                  {<ElapsedTime previousTime={files[0]._dateCreated} />}
                </span>
              </div>
            </div>

            {files[0]._comment && files[0]._comment.length && (
              <TextWithLineBreaksReadMore
                wordCount={files[0]._comment.length}
                classname="Comment"
                limit={isMobile ? 20 : 500}
              >
                {files[0]._comment.replace(/<br\s?\/?>/g, "\n")}
              </TextWithLineBreaksReadMore>
            )}
          </div>
        </aside>
      </div>
    </div>
  ) : null;
}

export default PhotoSwiper;
