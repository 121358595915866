import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { RiMoreFill, RiCamera2Fill } from "react-icons/ri";

import { ElapsedTime, Timestamp } from "../../utils/DateTime";
import Axios from "../../libs/axios/Axios";
import "../timeline/Timeline.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import PhotoSwiper from "../../libs/swiper/Swiper";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "../../components/layout/imageList/ImageList";

import { Box } from "../../components/layout/Container/Container";
import { EditTimeline } from "./editTimeline/EditTimeline";

function Timeline() {
  const [timelinePhotoListARR, setTimelinePhotoListARR] = useState([]);

  const [slideARR, setSlideARR] = useState([]);
  const [fileID, setFileID] = useState(0);
  const [imgIndex, setImgIndex] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);

  const [metaId, setMetaId] = useState(0);

  const [isDropmenuShown, setIsDropmenuShown] = useState(false);
  const [editComment, setEditComment] = useState("");
  const [isEditPostShown, setIsEditPostShown] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const prmREF = searchParams.get("ref");

  useEffect(() => {
    API_GetTimelinePhotoList();
    ObserverIntersection();
  }, []);

  useEffect(() => {
    const minItems = 20;

    if (
      prmREF === "timeline" &&
      isVisible &&
      timelinePhotoListARR.length >= minItems
    )
      API_GetTimelinePhotoList();
  }, [isVisible]);

  useEffect(() => HandleSlideArrAndSelectedIndex(), [slideARR, fileID]);

  function ObserverIntersection() {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    });
    observer.observe(targetRef.current);
  }

  function API_GetTimelinePhotoList() {
    const limitRows = 20;
    const offSetRows = timelinePhotoListARR.length;

    const URL = "/api/select/timeline/photos/by-user?";
    const QUERY = `type=tml&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        if (res.data.length)
          setTimelinePhotoListARR([...timelinePhotoListARR, ...res.data]);
      })
      .catch((err) => console.error(err));
  }

  // --- 📌Shared Url Link ↓

  function HandleUrlCopyLink(type, metaId) {
    const orgin = window.location.origin;
    const pathname = "/shared/preview";
    const query = `?type=${type}&metaId=${metaId}`;
    const url = orgin + pathname + query;
    window.navigator.clipboard.writeText(url);
  }

  const TimelineItemList = useMemo(() => {
    return (
      <Box className="timeline_list_con">
        <ImageList>
          {timelinePhotoListARR.map((a, index) => {
            return (
              <ImageListItem
                key={index}
                classname="timeline_image-listing-item"
              >
                <div className="tml_menu_con">
                  <button
                    className="tml_menu_btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMetaId(a._metaID);
                      setIsDropmenuShown((show) => !show);
                    }}
                  >
                    <RiMoreFill style={{ fill: "black" }} />
                  </button>

                  {metaId === a._metaID && isDropmenuShown && (
                    <ul className="tml_menu_dropdown">
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          setMetaId(a._metaID);
                          setEditComment(a._comment);
                          setIsEditPostShown(true);
                          setIsDropmenuShown((show) => !show);
                        }}
                      >
                        Edit Post
                      </li>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDropmenuShown((show) => !show);
                          HandleDeleteSinglePost(a._metaID);
                        }}
                      >
                        Delete Post
                      </li>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          setMetaId(a._metaID);
                          HandleUrlCopyLink("tml", a._metaID);
                          setIsDropmenuShown((show) => !show);
                        }}
                      >
                        Copy Link
                      </li>
                    </ul>
                  )}
                </div>

                <LazyLoadImage
                  alt="tml"
                  src={a._files[0]._baseURL}
                  width={"100%"}
                  height={"100%"}
                  wrapperClassName="PR-Timeline_LazyLoadWrapper"
                  onClick={() => {
                    timelinePhotoListARR.map((c) => {
                      if (c._metaID === a._metaID) {
                        setSlideARR([c]);
                        setFileID(0);
                      }
                    });
                  }}
                />

                <Box
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.3rem",
                    fontSize: "1.5rem",
                    color: "white",
                  }}
                >
                  {a._files.length > 1 ? (
                    <>
                      <RiCamera2Fill
                        style={{ fontSize: "2rem", fill: "white" }}
                      />
                      {a._files.length}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  });

  function HandleSlideArrAndSelectedIndex() {
    if (slideARR.length) {
      const index = slideARR[0]._files.findIndex((idx) => {
        return idx._fileID === fileID && idx;
      });

      setImgIndex(index);
      setToggleModal(true);
    }
  }

  // --- Delete Single Post ↓

  function HandleDeleteSinglePost(metaId) {
    let data = { tmlId: metaId, type: "tml" };

    const obj = Object.values(data).every((value) => !!value);
    if (!obj)
      alert("Failed to Delete Post. Refresh your browser and try again.");

    if (obj) {
      let msg = "Are you sure you want to delete this post?";
      if (window.confirm(msg) == true) {
        API_DeleteSinglePost(data);
      } else {
        return false;
      }
    }
  }

  function API_DeleteSinglePost(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/delete/timeline/single-post",
      data: data,
    })
      .then((res) => {
        if (res.data.status) {
          let deleteObj = timelinePhotoListARR.filter(
            (id) => id._metaID !== res.data.metaId
          );
          setTimelinePhotoListARR(deleteObj);
        }
      })
      .catch((err) => console.error(err));
  }

  // -----------------------------------------------
  // ----- Update Timline Post ---------------------
  // -----------------------------------------------

  function HandleUpdateSinglePost(metaId, comment) {
    let updateTimelinePost = {
      metaId: metaId,
      comment: comment.trim(),
      timestamp: Timestamp(),
    };

    const isEmpty = Object.values(updateTimelinePost).every(
      (val) => val !== "" && val !== null
    );

    if (!isEmpty) alert("Comment field is required.");
    if (isEmpty) API_UpdateTimelinePost(updateTimelinePost);
  }

  function API_UpdateTimelinePost(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/update/timeline/single-post",
      data: data,
    })
      .then((res) => {
        if (res.data.status) {
          let updateItem = timelinePhotoListARR.map((item) => {
            if (item._metaID === res.data.metaId) {
              item._comment = res.data.comment;
            }
            return item;
          });

          setTimelinePhotoListARR(updateItem);
          setIsEditPostShown(false);
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <Fragment>
      <Box className="PR-Tml_Content_Box">
        <Box
          className="PR-Tml_Header_Box"
          style={{ marginBottom: timelinePhotoListARR.length ? "20px" : "0" }}
        >
          <span
            className="PR-Tml_AddPhoto"
            onClick={() => setSearchParams({ ref: "addPhoto" })}
          >
            + Add Photo
          </span>
        </Box>

        {timelinePhotoListARR.length ? TimelineItemList : null}

        {isEditPostShown &&
          timelinePhotoListARR.map((a) => {
            if (a._metaID === metaId) {
              return (
                <EditTimeline
                  key={a._metaID}
                  enteredBy={a._enteredBy}
                  comment={editComment}
                  date={<ElapsedTime previousTime={a._dateCreated} />}
                  photo={a._adminPhoto}
                  commentOnChange={(e) => {
                    setEditComment(e.target.value);
                  }}
                  onSave={() => HandleUpdateSinglePost(a._metaID, editComment)}
                  closeModal={() => setIsEditPostShown(false)}
                  showModal={isEditPostShown}
                />
              );
            }
          })}

        <div ref={targetRef} className="intersection-observer"></div>
      </Box>

      <PhotoSwiper
        files={slideARR}
        index={imgIndex}
        closeModal={() => setToggleModal(false)}
        showModal={toggleModal}
      />
    </Fragment>
  );
}

export default Timeline;
