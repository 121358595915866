import { format, parseISO, formatDistanceToNow } from "date-fns";

export function ElapsedTime({ previousTime }) {
  if (!previousTime) return false;

  let timeAgo = "";
  if (previousTime) {
    const date = parseISO(previousTime);
    const timePeriod = formatDistanceToNow(date);
    timeAgo = `${timePeriod}`;

    return timeAgo;
  }
}

export function GetFullDate({ date }) {
  const fullDate = format(new Date(date), "dd-MM-yyyy");
  return fullDate;
}

export function GetYear({ date }) {
  const year = format(new Date(date), "yyyy");
  return year;
}

export function CheckItemExpiryDate(date) {
  const message = "Expired";
  const today = Date.parse(new Date());
  const expiryDate = Date.parse(new Date(date));
  if (expiryDate < today) return message;
}

export function Timestamp() {
  let d = new Date();
  d.setTime(d.getTime() - d.getTimezoneOffset() * 60000);
  let timestamp = d.toISOString().slice(0, 19).replace("T", " ");

  return timestamp;
}
