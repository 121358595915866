import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./layout/header/Header";
import Feed from "./pages/home/Feed";
import Photos from "./pages/photos/Photos";
import Dashboard from "./features/pages/dashboard/Dashboard";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import ForgotPassword from "./features/pages/password/ForgotPassword";
import ResetPassword from "./features/pages/password/ResetPassword";
import About from "./pages/about/About";
import SharedUrlPreview from "./pages/shared/SharedUrlPreview";
import Page404 from "./pages/error/Page404";
import { NetworkError } from "./pages/error/Error";
import Adventure from "./pages/blog/adventure/melbourne/Adventure";

import ProtectedRoute from "./routes/Protected";
import { CheckLoginStatus } from "./pages/auth/Auth";

import ReactGA from "react-ga";
ReactGA.initialize("UA-255819755-1");

function App() {
  const isLoggedIn = CheckLoginStatus();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Header isAuth={isLoggedIn} />

      <Routes>
        <Route path="/" element={<Feed isAuth={isLoggedIn} />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/about" element={<About />} />
        <Route path="/shared/preview" element={<SharedUrlPreview />} />
        <Route path="/signup" element={isLoggedIn ? <Feed /> : <Signup />} />
        <Route path="/admin" element={isLoggedIn ? <Feed /> : <Login />} />
        <Route path="/blog/adventure/melbourne" element={<Adventure />} />

        <Route
          path="/resetPassword"
          element={isLoggedIn ? <Feed /> : <ResetPassword />}
        />
        <Route
          path="/forgotPassword"
          element={isLoggedIn ? <Feed /> : <ForgotPassword />}
        />

        <Route path="/error500" element={<NetworkError />} />
        <Route path="*" element={<Page404 />} />

        <Route path="/*" element={<ProtectedRoute isAuth={isLoggedIn} />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
