import React from "react";
import "../imageList/ImageList.css";

export function ImageList({ margin, children }) {
  return (
    <div style={{ gap: margin }} className={`imgList`}>
      {children}
    </div>
  );
}

export function ImageListItem({ classname, itemSize, children }) {
  return (
    <div className={`imgListItem imgListItem_${itemSize} ${classname}`}>
      {children}
    </div>
  );
}

export function ImageListItemBar({
  title,
  totalItems,
  position,
  dateCreated,
  itemBarClick,
}) {
  return (
    <div
      className={`imgListItemBar imgListItemBarPosition_${position}`}
      onClick={(e) => {
        e.stopPropagation();
        itemBarClick();
      }}
    >
      <div className="imgListItemMeta">
        <span className="imgListBarTitle">{title}</span>
        <span className="imgListBarTotalItems">{totalItems}</span>
      </div>
      <span className="imgListBarDateCreated">{dateCreated}</span>
    </div>
  );
}
