import React from "react";
import "../buttons/Buttons.css";

export function Button({ onclick, style, size, position, variant, value }) {
  return (
    <button
      type="button"
      style={style}
      className={`Button ${position} ${size} ${variant}`}
      onClick={() => onclick()}
    >
      {value}
    </button>
  );
}
