import React, { useEffect } from "react";
import "./EditAlbum.css";

export function EditAlbum({
  enteredBy,
  date,
  albumName,
  albumNameOnChange,
  comment,
  onSave,
  photo,
  commentOnChange,
  showModal,
  closeModal,
}) {
  useEffect(() => {
    if (showModal) document.body.style.overflow = "hidden";
    if (!showModal) document.body.style.overflow = "unset";
  }, [showModal]);

  return showModal ? (
    <div className="alb_edit-post_modal">
      <div className="alb_edit-post_content">
        <div className="tml-edit-post-profile_con">
          <div className="alb_edit-post-profile-img_wrapper">
            <img src={photo} alt="profile" />
          </div>

          <div className="alb_edit-post-profile-detail_con">
            <span className="alb_edit-post-profile_name">{enteredBy}</span>
            <span className="alb_edit-post-profile_date">{date}</span>
          </div>
        </div>

        <input
          type="text"
          style={{ width: "100%", marginBottom: "1rem" }}
          value={albumName}
          onChange={(e) => albumNameOnChange(e)}
        />

        <textarea
          cols="30"
          rows="10"
          className="alb_edit-post-profile-comment_inp"
          placeholder="Comment"
          value={comment}
          onChange={(e) => commentOnChange(e)}
        ></textarea>

        <div className="alb_edit-post-controls_con">
          <button
            className="alb_edit-post-cancel_btn"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button className="alb_edit-post-save_btn" onClick={() => onSave()}>
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
