import React, { Fragment, useState, useRef } from "react";

import Axios from "../../libs/axios/Axios";
import { ElapsedTime, Timestamp } from "../../utils/DateTime";

import "./Comment.css";
import { RiSendPlaneFill } from "react-icons/ri";
import { TextareaAutoResize } from "./../../components/inputs/inputs/Inputs";
import { ContentEditable } from "./../../components/inputs/inputs/Inputs";

const SingleComment = (props) => {
  const [commentVal, setCommentVal] = useState("");
  const [openReply, setOpenReply] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [SubmitType, setSubmitType] = useState("Edit");
  const [replyStatus, setReplyStatus] = useState("Reply");

  const submitTypeRef = useRef(null);

  const HandleInputOnChange = (e) => setCommentVal(e.currentTarget.value);

  const HandleContentEditableOnChange = (e) =>
    setCommentVal(e.currentTarget.innerText);

  const OpenReplyBox = () => {
    if (replyStatus === "Cancel") {
      setCommentVal("");
      setReplyStatus("Reply");
      setSubmitType("Edit");
      setOpenReply(false);
      setIsEditable(false);
    }

    if (replyStatus === "Reply") {
      setCommentVal("");
      setReplyStatus("Cancel");
      setOpenReply(true);
    }
  };

  const AddComment = () => {
    API_AddSingleComment();
    setReplyStatus("Reply");
    setOpenReply(false);
  };

  const EditComment = () => {
    if (SubmitType === "Edit") {
      setSubmitType("Save");
      setReplyStatus("Cancel");
      setIsEditable(true);
    }

    if (SubmitType === "Save") {
      setSubmitType("Edit");
      setReplyStatus("Reply");
      setIsEditable(false);
      API_EditComment();
    }
  };

  const DeleteComment = () => {
    const result = window.confirm("Delete?");
    if (result) API_DeleteComment(props.comment._commentId);
  };

  const API_AddSingleComment = () => {
    const addsingleComment = {
      uniqueId: new Date().getTime(),
      content: commentVal.trim(),
      postId: props.postId,
      postType: props.postType,
      responseTo: props.comment._commentId,
      rootId: !props.comment._responseTo
        ? props.comment._commentId
        : props.comment._rootId,
      createdAt: Timestamp(),
      updatedAt: Timestamp(),
    };

    const isEmpty = Object.values(addsingleComment).every(
      (val) => val !== "" && val !== null
    );

    if (!isEmpty) alert("Write a comment");

    if (isEmpty) {
      Axios({
        method: "POST",
        type: "json",
        url: "/api/comment/add-single-comment",
        data: addsingleComment,
      })
        .then((res) => {
          if (res.data.success) {
            setCommentVal("");
            setOpenReply(false);

            props.refreshAddFunction(res.data.result);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const API_EditComment = () => {
    const updateRootComment = {
      postId: props.comment._postId,
      uniqueId: props.comment._commentId,
      content: commentVal.trim(),
      updatedAt: Timestamp(),
    };

    const isEmpty = Object.values(updateRootComment).every(
      (val) => val !== "" && val !== null
    );

    if (!isEmpty) alert("Write a comment");

    if (isEmpty) {
      Axios({
        method: "POST",
        type: "json",
        url: "/api/comment/update-comment",
        data: updateRootComment,
      })
        .then((res) => {
          if (res.data.success) props.refreshEditFunction(updateRootComment);
        })
        .catch((err) => console.error(err));
    }
  };

  const API_DeleteComment = (commentId) => {
    const idObj = {
      postId: props.comment._postId,
      uniqueId: commentId,
      postType: props.comment._postType,
    };

    const isEmpty = Object.values(idObj).every((value) => !!value);

    if (isEmpty) {
      Axios({
        method: "POST",
        type: "json",
        url: "/api/comment/delete-comment",
        data: idObj,
      })
        .then((res) => {
          if (res.data.success) {
            props.refreshDeleteFunction(idObj);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="comment_single-main__con">
      <div className="single-comment">
        <div className="writer-detail">
          <div className="avatar">
            <img src={props.comment._photo} alt={"avatar"} />
          </div>
        </div>

        <div className="col-content">
          <div className="comment_author-detail_con">
            <div className="author">{props.comment._name}</div>
            <div className="timestamp">
              <ElapsedTime previousTime={props.comment._updatedAt} />
            </div>
          </div>

          <ContentEditable
            contentEditable={isEditable}
            onInput={(e) => HandleContentEditableOnChange(e)}
            value={props.comment._content}
          />

          {/* --- 📌Show actions by authorised user ↓ */}

          <div className="actions">
            {props.authorised && (
              <button
                key="comment-reply_btn"
                type="button"
                onClick={() => {
                  OpenReplyBox();
                }}
              >
                {replyStatus}
              </button>
            )}

            {props.comment._adminId === props.authorised.authId && (
              <Fragment>
                <button
                  key="comment-edit_btn"
                  type="button"
                  ref={submitTypeRef}
                  onClick={() => EditComment()}
                >
                  {SubmitType}
                </button>

                <button
                  key="comment-delete_btn"
                  type="button"
                  onClick={() => DeleteComment()}
                >
                  Delete
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      {openReply && (
        <div className="comment_root-comment-input__con reply">
          <div className="comment_root-avatar__wrapp">
            <img src={props.authorised.avatar} alt="avatar" />
          </div>

          <div className="comment_enter-comment__form">
            <TextareaAutoResize
              value={commentVal}
              onChange={(e) => HandleInputOnChange(e)}
              placeholder={`@${props.comment._name}`}
              autoFocus={true}
            />
          </div>

          <button
            type="button"
            onClick={() => AddComment()}
            className="comment_enter-comment__button"
          >
            <RiSendPlaneFill className="icon_send" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleComment;
