import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../../libs/axios/Axios";
import "./Auth.css";
import { Button } from "../../components/inputs/buttons/Buttons";

import {
  Content,
  Box,
} from "../../components/layout/Container/Container";

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [key, setKey] = useState("");

  const [showError, setShowError] = useState(false);
  const [signupError, setSignupError] = useState("");

  const navigateTo = useNavigate();

  useEffect(() => {
    document.title = "Xfa Circle - Register";
  }, []);

  function HandleSignup(data) {
    Axios({
      method: "POST",
      type: "JSON",
      url: "/api/insert/signup/admin",
      data: data,
    })
      .then((res) => {
        if (res.data.status) navigateTo(res.data.redirectURL);

        if (!res.data.status) {
          setShowError(true);
          setSignupError(res.data.message);
        }
      })
      .catch((err) => console.error(err));
  }

  function HandleInputValidation() {
    const obj = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password: password.trim(),
      role: "admin".trim(),
      key: key.trim(),
    };

    const isEmpty = Object.values(obj).every(
      (value) => value !== "" && value !== undefined && value !== null
    );

    if (!isEmpty) {
      alert("All fields marked with an asterisk (*) are required.");
      return false;
    }

    const mailFormat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mailFormat.test(obj.email)) {
      alert("You have entered an invalid email address.");
      return false;
    }

    if (password.length < 8) {
      alert("Password must be atleast 8 characters.");
      return false;
    }
    HandleSignup(obj);
  }

  function CaptitalizeInput(str, setState) {
    const con = str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    setState(con);
  }

  return (
      <Content className="FULL_WIDTH">
        <Box className="article_signup SMALL_WIDTH">
          <Box className="con_signup-content">
            <Box className="box_form-header">
              <span className="form-heading">Admin Registration Form</span>
              {showError && (
                <span className="login-error-msg">{signupError}</span>
              )}
            </Box>

            <input
              type="text"
              required
              value={firstName}
              autoFocus
              placeholder="First name"
              onChange={(e) => CaptitalizeInput(e.target.value, setFirstName)}
            />

            <input
              type="text"
              required
              value={lastName}
              placeholder="Last name"
              onChange={(e) => CaptitalizeInput(e.target.value, setLastName)}
            />

            <Box className="con_signup-key_pub">
              <input
                type="email"
                name="email"
                required
                value={email}
                placeholder="Email Address*"
                className="inp_signup-email_pub"
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />

              <input
                type="text"
                name="key"
                required
                value={key}
                placeholder="Key*"
                className="inp_signup-key_pub"
                onChange={(e) => setKey(e.target.value)}
              />
            </Box>

            <input
              type="password"
              name="password"
              required
              value={password}
              placeholder="Password*"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              style={{ marginTop: "20px" }}
              size={"Medium"}
              position={"Center"}
              variant={"Filled"}
              value={"Register"}
              onclick={() => HandleInputValidation()}
            />

            <Box className="box_navigation-links">
              <Link to="/admin" className="navlink">
                Admin Login
              </Link>
            </Box>
          </Box>
        </Box>
      </Content>
  );
}

export default Signup;
