import React from "react";
import "../../inputs/select/Select.css";

export const SelectDropdown = ({ onclick, children, classname }) => {
  return (
    <div className={`selectDropdown ${classname}`} onClick={() => onclick()}>
      {children}
    </div>
  );
};

export const SelectInput = ({ classname, children }) => {
  return <span className={`selectInput ${classname}`}>{children}</span>;
};

export const SelectList = ({ children }) => {
  return <ul className="selectList">{children}</ul>;
};

export const SelectItem = ({ onclick, children }) => {
  return (
    <li className="selectItem" onClick={(e) => onclick(e)}>
      {children}
    </li>
  );
};
