import React, { useState } from "react";
import Axios from "../../../libs/axios/Axios";
import { Content, Box } from "../../../components/layout/Container/Container";
import { Button } from "../../../components/inputs/buttons/Buttons";
import "./Password.css";

function ForgotPassword() {
  const [message, setMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(true);
  const [email, setEmail] = useState("");

  function API_ForgotPassword(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/update/forgot-password/admin",
      data: data,
    }).then((res) => {
      if (res.data.status) {
        setMessage(res.data.message);
        setIsEmailSent(false);
      }
      if (!res.data.status) setMessage(res.data.message);
    });
  }

  function HandleForgotPasswordSumbit() {
    let match = /\S+@\S+\.\S+/;
    let validEmail = match.test(email);

    let data = {
      email: validEmail && email,
    };

    const obj = Object.values(data).every((value) => !!value);
    if (!obj) {
      alert("Enter your email to reset your password.");
      return;
    }
    if (obj) API_ForgotPassword(data);
  }

  return (
    <Content className={"pwd_content"}>
      <Box className="reset-pwd_content">
        <Box className="reset-pwd_heading_con">
          <h1>Forgot Password</h1>
          <h2>Enter your email address</h2>

          {message === "" ? null : (
            <span
              style={{
                marginTop: "2rem",
                color: "white",
                padding: "0.5rem 1rem 0.5rem 1rem",
                borderRadius: "5px",
                background: isEmailSent ? "red" : "green",
              }}
            >
              {message}
            </span>
          )}
        </Box>

        {isEmailSent && (
          <>
            <Box className="reset-pwd_inp_con">
              <label htmlFor="">Email</label>
              <input
                type="email"
                value={email}
                autoFocus={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Button
              value={"Forgot Password"}
              variant={"Filled"}
              style={{ marginTop: "2rem" }}
              onclick={() => HandleForgotPasswordSumbit()}
            />

            <a
              href="/admin"
              style={{
                marginTop: "2rem",
                color: "blue",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              Login
            </a>
          </>
        )}
      </Box>
    </Content>
  );
}

export default ForgotPassword;
