import React, { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RiHistoryLine, RiImageLine } from "react-icons/ri";
import "./Dashboard.css";

import {
  Content,
  Box,
  Sidebar,
} from "../../../components/layout/Container/Container";

import Timeline from "../../timeline/Timeline";
import Album from "../../album/Album";
import AddAlbum from "../../album/addAlbum/AddAlbum";
import AddTimeline from "../../timeline/addTimeline/AddTimeline";

function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const prmREF = searchParams.get("ref");

  useEffect(() => {
    document.title = "Xfa Circle - Dashboard";
  }, []);

  useEffect(() => {
    if (!prmREF) setSearchParams({ ref: "timeline" });
  }, [searchParams]);

  return (
    <Fragment>
      <Content>
        <Box className="PR-Dash_Grouping">
          <Box className="PR-Dash_Grid">
            <Sidebar className="PR-Dash_RightSidebar_Box">
              <span className="PR-Dash_RightSidebar_Heading">Dashboard</span>

              <ul className="PR-Dash_RightSidebar_ul">
                <li
                  style={{
                    background:
                      !prmREF || prmREF === "timeline" ? "#e6ecf0" : null,
                  }}
                  onClick={() => setSearchParams({ ref: "timeline" })}
                >
                  <RiHistoryLine className="PR-Dash_RightSidebar_icon" />
                  All Timeline
                </li>

                <li
                  style={{
                    background: prmREF === "albums" ? "#e6ecf0" : null,
                  }}
                  onClick={() => setSearchParams({ ref: "albums" })}
                >
                  <RiImageLine className="PR-Dash_RightSidebar_icon" />
                  All Albums
                </li>
              </ul>
            </Sidebar>

            <Box className="PR-Dash_ExternalPages_Box">
              {prmREF === "timeline" ? <Timeline /> : ""}
              {prmREF === "albums" ? <Album /> : ""}
              {prmREF === "addPhoto" ? <AddTimeline /> : ""}
              {prmREF === "addAlbum" ? <AddAlbum /> : ""}
            </Box>
          </Box>
        </Box>
      </Content>
    </Fragment>
  );
}

export default Dashboard;
