import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import { RiMoreFill, RiDeleteBin2Fill, RiArrowLeftSLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";

import Axios from "../../libs/axios/Axios";
import "../album/Album.css";

import PhotoSwiper from "../../libs/swiper/Swiper";
import { ElapsedTime, Timestamp } from "../../utils/DateTime";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "../../components/layout/imageList/ImageList";

import { Box } from "../../components/layout/Container/Container";
import { EditAlbum } from "./editAlbum/EditAlbum";

function Album() {
  const [albumListARR, setAlbumListARR] = useState([]);
  const [isalbumPhotoListShow, setIsAlbumPhotoListShow] = useState(false);
  const [albumCategoriesARR, setAlbumCategoriesARR] = useState([]);

  const [albumPhotoListARR, setAlbumPhotoListARR] = useState([]);
  const [isAlbumListShow, setIsAlbumListShow] = useState(true);
  const [albID, setAlbID] = useState(0);

  const [slideARR, setSlideARR] = useState([]);
  const [fileID, setFileID] = useState(0);
  const [imgIndex, setImgIndex] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);

  const [category, setCategory] = useState("");

  const [metaId, setMetaId] = useState(0);
  const [isDropmenuShown, setIsDropmenuShown] = useState(false);
  const [editAlbumName, setEditAlbumName] = useState("");
  const [editComment, setEditComment] = useState("");
  const [isEditPostShown, setIsEditPostShown] = useState(false);

  const [deleteARR, setDeleteARR] = useState([]);

  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const prmREF = searchParams.get("ref");

  useEffect(() => {
    API_GetAlbumList();
    API_GetAlbumCategoryListByAdmin();
    ObserverIntersection();
  }, []);

  useEffect(() => {
    if (prmREF === "albums" && albID && !albumPhotoListARR.length) {
      API_GetAlbumListFilesByAdmin();
      HandleDeleteReset();
    }
  }, [albID]);

  useEffect(() => {
    const minItems = 20;

    if (
      isVisible &&
      prmREF === "albums" &&
      albumListARR.length >= minItems &&
      category === ""
    ) {
      API_GetAlbumList();
    }

    if (
      isVisible &&
      prmREF === "albums" &&
      albumListARR.length >= minItems &&
      !category.length
    ) {
      API_GetAlbumListByCategory();
    }

    if (
      isVisible &&
      prmREF === "albums" &&
      albID &&
      !albumPhotoListARR.length
    ) {
      API_GetAlbumListFilesByAdmin();
    }
  }, [isVisible]);

  useEffect(() => HandleSlideArrAndSelectedIndex(), [slideARR, fileID]);

  useEffect(() => {
    if (!category.length) return;
    API_GetAlbumListByCategory();
  }, [category]);

  function API_GetAlbumCategoryListByAdmin() {
    const URL = "/api/select/album/list/categories/admin";
    const ENDPOINT = URL;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => res.data && setAlbumCategoriesARR(res.data))
      .catch((err) => console.error(err));
  }

  function ObserverIntersection() {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    });
    observer.observe(targetRef.current);
  }

  // --- Select Album List ↓

  function API_GetAlbumList() {
    const limitRows = 20;
    const offSetRows = albumListARR.length;

    const URL = "/api/select/album/list/admin?";
    const QUERY = `type=alb&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        setAlbumListARR([...albumListARR, ...res.data]);
      })
      .catch((err) => console.error(err));
  }

  // --- Delete Single Post ↓

  function HandleDeleteSinglePost(albumId) {
    let data = { albumId: albumId, type: "alb" };

    const obj = Object.values(data).every((value) => !!value);
    if (!obj)
      alert("Failed to Delete Post. Refresh your browser and try again.");

    if (obj) {
      let msg = "Are you sure you want to delete this post?";
      if (window.confirm(msg) == true) {
        API_DeleteSinglePost(data);
      } else {
        return false;
      }
    }
  }

  function API_DeleteSinglePost(data) {
    Axios({
      method: "Post",
      type: "json",
      url: "/api/delete/album/by-album-id",
      data: data,
    })
      .then((res) => {
        if (res.data.status) {
          let deleteObj = albumListARR.filter(
            (id) => id._metaID !== res.data.metaId
          );
          setAlbumListARR(deleteObj);
        }
      })
      .catch((err) => console.error(err));
  }

  // --- Copy Link URL ↓

  function HandleCopyUrlLink(type, albId) {
    const orgin = window.location.origin;
    const pathname = "/shared/preview";
    const query = `?type=${type}&metaId=${albId}`;
    const url = orgin + pathname + query;
    window.navigator.clipboard.writeText(url);
  }

  const AlbumList = useMemo(() => {
    return (
      <div className="album_album-list_con">
        {albumCategoriesARR.length ? (
          <ul className="PR-Alb_FilterByCategory_Ul">
            {albumCategoriesARR.map((a, index) => {
              return (
                <li
                  key={index}
                  style={{
                    background:
                      category === a._category ? "#e7edf1" : "transparent",
                  }}
                  onClick={(e) => {
                    if (a._category === category) return false;
                    setAlbumListARR([]);
                    setCategory(a._category);
                  }}
                >
                  {a._category}
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}

        <ImageList>
          {albumListARR.map((a, index) => {
            return (
              <ImageListItem key={index}>
                <div className="alb_menu_con">
                  <button
                    className="alb_menu_btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMetaId(a._metaID);
                      setIsDropmenuShown((show) => !show);
                    }}
                  >
                    <RiMoreFill style={{ fill: "black" }} />
                  </button>

                  {metaId === a._metaID && isDropmenuShown && (
                    <ul className="alb_menu_dropdown">
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          setMetaId(a._metaID);
                          setEditAlbumName(a._albumName);
                          setEditComment(a._comment);
                          setIsEditPostShown(true);
                          setIsDropmenuShown((show) => !show);
                        }}
                      >
                        Edit Album
                      </li>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDropmenuShown((show) => !show);
                          HandleDeleteSinglePost(a._metaID);
                        }}
                      >
                        Delete Album
                      </li>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          setMetaId(a._metaID);
                          HandleCopyUrlLink("alb", a._metaID);
                          setIsDropmenuShown((show) => !show);
                        }}
                      >
                        Copy Link
                      </li>
                    </ul>
                  )}
                </div>

                <LazyLoadImage
                  alt="alb"
                  src={a._baseURL}
                  width={"100%"}
                  height={"100%"}
                  wrapperClassName="PR-Album_LazyLoadWrapper"
                  onClick={() => setAlbID(a._metaID)}
                />

                <ImageListItemBar
                  title={
                    a._albumName.length > 20
                      ? a._albumName.substring(0, 20) + ".."
                      : a._albumName
                  }
                  totalItems={a._totalFiles + " items, " + a._year}
                  itemBarClick={(e) => setAlbID(a._metaID)}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </div>
    );
  });

  // --- Album List Files ↓

  function API_GetAlbumListFilesByAdmin() {
    const limitRows = 20;
    const offSetRows = albumPhotoListARR.length;

    const URL = "/api/select/album/list/files/admin?";
    const QUERY = `type=alb&albumId=${albID}&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        if (res.data.length) {
          setAlbumPhotoListARR([...albumPhotoListARR, ...res.data]);
          setIsAlbumListShow(false);
          setIsAlbumPhotoListShow(true);
        }
      })
      .catch((err) => console.error(err));
  }

  const AlbumListFiles = useMemo(() => {
    return (
      <Box className="album_album-list-files_con">
        {albumPhotoListARR.length && (
          <Box className="album_files-header_con">
            <Box>
              <p
                className="album_files-title"
                onClick={() => {
                  setAlbumPhotoListARR([]);
                  setIsAlbumPhotoListShow(false);
                  setIsAlbumListShow(true);
                  HandleDeleteReset();
                  setAlbID(0);
                }}
              >
                <RiArrowLeftSLine />
                {albumPhotoListARR.length && albumPhotoListARR[0]._title}
              </p>

              <span className="album_files-date">
                <ElapsedTime previousTime={albumPhotoListARR[0]._dateCreated} />
              </span>
            </Box>

            {deleteARR.length ? (
              <Box className="album_files-btns_con">
                <button
                  className="album_files-save_btn"
                  onClick={() => HandleDeleteAlbumFilesOnSubmission()}
                >
                  Save
                </button>
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}

        <ImageList>
          {albumPhotoListARR.map((a) => {
            return a._files.map((b, index) => {
              return (
                <ImageListItem key={index}>
                  {albumPhotoListARR[0]._files.length > 1 && (
                    <RiDeleteBin2Fill
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "15px",
                        width: "25px",
                        height: "25px",
                        fill: "red",
                      }}
                      onClick={() => {
                        setDeleteARR([...deleteARR, b._fileID]);
                        RemoveFilesFromListTemporarily(b._fileID);
                      }}
                    />
                  )}

                  <LazyLoadImage
                    alt="alb"
                    src={b._baseURL}
                    width={"100%"}
                    height={"100%"}
                    wrapperClassName="PR-Album_LazyLoadWrapper"
                    onClick={() => {
                      albumPhotoListARR.map((c) => {
                        if (c._metaID === a._metaID) {
                          setSlideARR([c]);
                          setFileID(b._fileID);
                        }
                      });
                    }}
                  />
                </ImageListItem>
              );
            });
          })}
        </ImageList>
      </Box>
    );
  }, [albumPhotoListARR, albumCategoriesARR, deleteARR]);

  function HandleSlideArrAndSelectedIndex() {
    if (slideARR.length) {
      const index = slideARR[0]._files.findIndex((idx) => {
        return idx._fileID === fileID && idx;
      });

      setImgIndex(index);
      setToggleModal(true);
    }
  }

  // --- Query ↓

  function API_GetAlbumListByCategory() {
    const limitRows = 20;
    const offSetRows = albumListARR.length;

    const URL = "/api/select/album/list/category/query/admin?";
    const QUERY = `type=alb&category=${category}&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        if (res.data) {
          setAlbumListARR([...albumListARR, ...res.data]);
        }
      })
      .catch((err) => console.error(err));
  }

  // -------------------------------------------
  // ----- Handle States Reset -----------------
  // -------------------------------------------

  function HandleDeleteReset() {
    setDeleteARR([]);
  }

  // -----------------------------------------------
  // ----- Update Album Post -----------------------
  // -----------------------------------------------

  function HandleUpdateSingleAlbumPost(metaId, albumName, comment) {
    let updateAlbumPost = {
      metaId: metaId,
      albumName: albumName,
      comment: comment.trim(),
      timestamp: Timestamp(),
    };

    const isEmpty = Object.values(updateAlbumPost).every(
      (val) => val !== "" && val !== null
    );

    if (!isEmpty) alert("Complete the following fields.\nAlbum name\nComment");
    if (isEmpty) API_UpdateSingleAlbumPost(updateAlbumPost);
  }

  function API_UpdateSingleAlbumPost(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/update/album/single-post",
      data: data,
    })
      .then((res) => {
        if (res.data.status) {
          let updateItem = albumListARR.map((item) => {
            if (item._metaID === res.data.metaId) {
              item._albumName = res.data.albumName;
              item._comment = res.data.comment;
            }
            return item;
          });
          setAlbumListARR(updateItem);
          setIsEditPostShown(false);
        }
      })
      .catch((err) => console.error(err));
  }

  // -----------------------------------------------
  // ----- Delete Album Files ----------------------
  // -----------------------------------------------

  function RemoveFilesFromListTemporarily(fileId) {
    const removeFile = albumPhotoListARR[0]._files.filter(
      (b) => b._fileID !== fileId
    );
    albumPhotoListARR[0]._files = removeFile;
  }

  function HandleDeleteAlbumFilesOnSubmission() {
    let data = [
      {
        albumId: albumPhotoListARR[0]._metaID,
        type: "alb",
        files: deleteARR,
      },
    ];

    if (!data[0].files.length) return false;
    const obj = Object.values(data[0]).every((value) => !!value);
    if (!obj) console.error("Invalid selected input");
    if (obj) API_DeleteAlbumFiles(data);
  }

  function API_DeleteAlbumFiles(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/delete/album/files/by-file-id",
      data: data,
    })
      .then((res) => {
        if (res.data.status) return;
      })
      .catch((err) => console.error(err));
  }

  return (
    <Fragment>
      <Box className="PR-Alb_Content_Box">
        <Box
          className="PR-Alb_Header_Box"
          style={{ marginBottom: albumListARR.length ? "20px" : "0" }}
        >
          <span
            className="PR-Alb_AddAlbum"
            onClick={() => setSearchParams({ ref: "addAlbum" })}
          >
            + Add Album
          </span>
        </Box>

        {/* ----- Show Albums List & Files ↓ */}

        {!isalbumPhotoListShow && albumListARR.length ? AlbumList : null}
        {isalbumPhotoListShow && albumListARR.length ? AlbumListFiles : null}

        {isEditPostShown &&
          albumListARR.map((a) => {
            if (a._metaID === metaId) {
              return (
                <EditAlbum
                  key={a._metaID}
                  enteredBy={a._enteredBy}
                  albumName={editAlbumName}
                  albumNameOnChange={(e) => setEditAlbumName(e.target.value)}
                  comment={editComment === null ? "" : editComment}
                  date={<ElapsedTime previousTime={a._lastUpdated} />}
                  photo={a._userPhoto}
                  commentOnChange={(e) => {
                    setEditComment(e.target.value);
                  }}
                  onSave={() =>
                    HandleUpdateSingleAlbumPost(
                      a._metaID,
                      editAlbumName,
                      editComment
                    )
                  }
                  closeModal={() => setIsEditPostShown(false)}
                  showModal={isEditPostShown}
                />
              );
            }
          })}

        <div ref={targetRef} className="intersection-observer"></div>
      </Box>

      <PhotoSwiper
        files={slideARR}
        index={imgIndex}
        closeModal={() => setToggleModal(false)}
        showModal={toggleModal}
      />
    </Fragment>
  );
}

export default Album;
