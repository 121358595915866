import React, { Fragment, useEffect, useState } from "react";
import SingleComment from "./SingleComment";

function ReplyComment(props) {
  const [childCommentNumber, setChildCommentNumber] = useState(0);
  const [openReplyComments, setOpenReplyComments] = useState(false);

  useEffect(() => {
    let commentNumber = 0;
    props.CommentLists.map((comment) => {
      if (Number(comment._responseTo) === Number(props.parentCommentId)) {
        commentNumber++;
      }
    });
    setChildCommentNumber(commentNumber);
  }, [props.CommentLists]);

  const handleChange = () => setOpenReplyComments(!openReplyComments);

  return (
    <Fragment>
      {childCommentNumber > 0 && (
        <span
          style={{
            display: "block",
            fontSize: "0.8rem",
            marginLeft: "30px",
            marginTop: "10px",
            marginBottom: "10px",
            color: "blue",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={handleChange}
        >
          {childCommentNumber === 1
            ? childCommentNumber + " reply"
            : childCommentNumber + " replies"}
        </span>
      )}

      {openReplyComments &&
        props.CommentLists &&
        props.CommentLists.map((comment) => {
          return (
            <div
              key={comment._commentId}
              className="replyComment_single-comment__con"
            >
              {Number(comment._responseTo) ===
                Number(props.parentCommentId) && (
                <div>
                  <SingleComment
                    comment={comment}
                    postId={props.postId}
                    adminId={props.adminId}
                    postType={props.postType}
                    authorised={props.authorised}
                    refreshAddFunction={props.refreshAddFunction}
                    refreshEditFunction={props.refreshEditFunction}
                    refreshDeleteFunction={props.refreshDeleteFunction}
                  />
                  <ReplyComment
                    postId={props.postId}
                    adminId={props.adminId}
                    postType={props.postType}
                    authorised={props.authorised}
                    CommentLists={props.CommentLists}
                    parentCommentId={comment._commentId}
                    refreshAddFunction={props.refreshAddFunction}
                    refreshEditFunction={props.refreshEditFunction}
                    refreshDeleteFunction={props.refreshDeleteFunction}
                  />
                </div>
              )}
            </div>
          );
        })}
    </Fragment>
  );
}

export default ReplyComment;
