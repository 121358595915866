import React, { useEffect } from "react";
import "./EditTimeline.css";

import { TextareaAutoResize } from "../../../components/inputs/inputs/Inputs";

export function EditTimeline({
  enteredBy,
  date,
  comment,
  onSave,
  photo,
  commentOnChange,
  showModal,
  closeModal,
}) {
  useEffect(() => {
    if (showModal) document.body.style.overflow = "hidden";
    if (!showModal) document.body.style.overflow = "unset";
  }, [showModal]);

  return showModal ? (
    <div className="tml_edit-post_modal">
      <div className="tml_edit-post_content">
        <div className="tml-edit-post-profile_con">
          <div className="tml_edit-post-profile-img_wrapper">
            <img src={photo} alt="profile" />
          </div>

          <div className="tml_edit-post-profile-detail_con">
            <span className="tml_edit-post-profile_name">{enteredBy}</span>
            <span className="tml_edit-post-profile_date">{date}</span>
          </div>
        </div>

        <TextareaAutoResize
          style={{
            resize: "none",
            overflowY: "auto",
            width: "100%",
            maxHeight: "250px",
            marginTop: "15px",
          }}
          value={comment}
          onChange={(e) => commentOnChange(e)}
          rows={2}
          placeholder={"Say something about this photo"}
          autoFocus={true}
        />

        <div className="tml_edit-post-controls_con">
          <button
            className="tml_edit-post-cancel_btn"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button className="tml_edit-post-save_btn" onClick={() => onSave()}>
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
