import React, { Fragment, useState } from "react";
import Axios from "../../libs/axios/Axios";

// ---- Comment Comps ↓
import SingleComment from "./SingleComment";
import ReplyComment from "./ReplyComment";
import { RiSendPlaneFill } from "react-icons/ri";
import { TextareaAutoResize } from "./../../components/inputs/inputs/Inputs";
import { Timestamp } from "../../utils/DateTime";
// --------------------------------

export function Comments(props) {
  const [Comment, setComment] = useState("");

  const HandleInputOnChange = (e) => {
    setComment(e.currentTarget.value);
  };

  const AddRootComment = () => API_AddRootComment();

  const API_AddRootComment = () => {
    const addRootComment = {
      uniqueId: new Date().getTime(),
      content: Comment.trim(),
      postId: props.postId,
      postType: props.postType,
      createdAt: Timestamp(),
      updatedAt: Timestamp(),
    };

    const isEmpty = Object.values(addRootComment).every(
      (val) => val !== "" && val !== null
    );

    if (!isEmpty) alert("Write a comment");

    if (isEmpty) {
      Axios({
        method: "POST",
        type: "json",
        url: "/api/comment/add-root-comment",
        data: addRootComment,
      })
        .then((res) => {
          if (res.data.success) {
            setComment("");
            props.refreshAddFunction(res.data.result);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="comment_main-content__con">
      {/* 📌Root Comment Form */}

      {props.showCommentBox &&
        props.authorised.status &&
        Number(props.postId) === Number(props.refId) &&
        props.postType === props.refType && (
          <Fragment>
            <div
              className="comment_root-comment-input__con"
              style={{
                marginBottom: props.showCommentBox && "10px",
              }}
            >
              <div className="comment_root-avatar__wrapp">
                <img src={props.authorised.avatar} alt="avatar" />
              </div>

              <div className="comment_enter-comment__form">
                <TextareaAutoResize
                  value={Comment}
                  onChange={(e) => HandleInputOnChange(e)}
                  placeholder={"Write a comment"}
                  autoFocus={true}
                />
              </div>

              <button
                type="button"
                onClick={AddRootComment}
                className="comment_enter-comment__button"
              >
                <RiSendPlaneFill className="icon_send" />
              </button>
            </div>
          </Fragment>
        )}

      {/* 📌Comment Lists */}

      {props.showCommentLists &&
        Number(props.postId) === Number(props.refId) &&
        props.postType === props.refType &&
        props.CommentLists.map((comment) => {
          return Number(!comment._responseTo) &&
            Number(props.postId) === Number(comment._postId) &&
            props.postType === comment._postType ? (
            <Fragment key={comment._commentId}>
              <SingleComment
                comment={comment}
                postId={props.postId}
                adminId={props.adminId}
                postType={props.postType}
                authorised={props.authorised}
                refreshAddFunction={props.refreshAddFunction}
                refreshEditFunction={props.refreshEditFunction}
                refreshDeleteFunction={props.refreshDeleteFunction}
              />

              <ReplyComment
                postId={props.postId}
                adminId={props.adminId}
                postType={props.postType}
                authorised={props.authorised}
                CommentLists={props.CommentLists}
                parentCommentId={comment._commentId}
                refreshAddFunction={props.refreshAddFunction}
                refreshEditFunction={props.refreshEditFunction}
                refreshDeleteFunction={props.refreshDeleteFunction}
              />
            </Fragment>
          ) : null;
        })}
    </div>
  );
}

export default Comments;
