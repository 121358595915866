import React, { useState } from "react";
import "./Text.css";

export function TextWithLineBreaks({ value, className }) {
  return (
    <p className={className} style={{ whiteSpace: "pre-line" }}>
      {value.replace(/<br\s?\/?>/g, "\n")}
    </p>
  );
}

export function TextWithLineBreaksReadMore({
  classname,
  limit,
  children,
  wordCount,
  style,
  styleReadMore,
}) {
  const [isReadMoreShown, setIsReadMoreShown] = useState(false);

  const toggleBtn = () => setIsReadMoreShown((prevState) => !prevState);

  return (
    <div style={styleReadMore} className="TextWithLineBreaksReadMore">
      <span style={style} className={`ReadMore ${classname}`}>
        {isReadMoreShown ? children : children.substring(0, limit).trim()}

        {Number(wordCount) > limit && (
          <button className="ReadMore_Btn" onClick={toggleBtn}>
            {isReadMoreShown ? " - Read Less" : " - Read More"}
          </button>
        )}
      </span>
    </div>
  );
}
