import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../libs/axios/Axios";
import "./AddAlbum.css";

import { RiArrowDropDownLine, RiCloseCircleFill } from "react-icons/ri";
import { Box } from "../../../components/layout/Container/Container";
import { Button } from "../../../components/inputs/buttons/Buttons";
import { TextareaAutoResize } from "../../../components/inputs/inputs/Inputs";
import { Timestamp } from "../../../utils/DateTime";

import {
  SelectDropdown,
  SelectInput,
  SelectList,
  SelectItem,
} from "../../../components/inputs/select/Select";

import { Modal } from "../../../components/utils/modal/Modal";
import camera from "../../../assets/images/camera.png";
import gifSpinner from "../../../assets/images/spinner.gif";

const CategoriesList = [
  "General",
  "Adventure",
  "Celebration",
  "Sport",
  "Events",
  "Family",
];

CategoriesList.sort();

function AddAlbum() {
  const [inputFilesARR, setInputFilesARR] = useState([]);
  const [albName, setAlbName] = useState("");
  const [comment, setComment] = useState("");
  const [albumDate, setAlbumDate] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [isDropdownShow, setIsDropdownShow] = useState(false);
  const [category, setCategory] = useState("");

  const navigateTo = useNavigate();
  const fileRef = useRef(null);

  useEffect(() => getDate(), []);

  function getDate() {
    let dt = new Date();
    let date = (dt.getDate() + 1).toString().padStart(2, "0");
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
    let year = dt.getFullYear();

    let shortDate = `${year}-${month}-${date}`;
    setAlbumDate(shortDate);
  }

  // ----- Step 1 : Upload File ↓

  function HandleFileInputOnChange(e) {
    // A min. of two files allow ↓

    if (!e.target.value) return false;

    if (e.target.files) {
      const filterByImageType = Array.from(e.target.files).filter((file) => {
        const imgFormat = file.type.split("/")[1];
        if (imgFormat == "jpeg" || imgFormat == "png") {
          return file;
        }
      });

      const outputFiles = Array.from(filterByImageType).map((file) =>
        URL.createObjectURL(file)
      );

      if (outputFiles.length)
        setInputFilesARR([...inputFilesARR, ...outputFiles]);
    }
  }

  // ----- Step 2 : Preview Selected Files ↓

  function HandlePreviewSelectedFiles() {
    return (
      <>
        {inputFilesARR.map((file, index) => {
          return (
            <div
              key={index}
              className="AddAlb_ImgPreview_Wrapper"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            >
              <img className="AddAlb_ImgSrc" src={file} alt="alb" />
              <RiCloseCircleFill
                onClick={() => HandleFileInputDeletion(file)}
              />
            </div>
          );
        })}

        {!inputFilesARR.length && (
          <div
            className="AddAlb_ImgPreview_Wrapper"
            style={{ padding: "20px", height: "300px" }}
          >
            <img
              className="AddAlb_ImgSrc"
              src={camera}
              alt="alb"
              style={{ width: !inputFilesARR.length && "128px" }}
            />
          </div>
        )}
      </>
    );
  }

  // ----- Step 3 : Compress Selected Files ↓

  function CompressFile() {
    const img = document.querySelectorAll(".AddAlb_ImgSrc");
    const output = [];
    for (let x = 0; x < img.length; x++) {
      let canvas = document.createElement("canvas");
      canvas.width = 1200;
      let maxWidth = canvas.width;
      let originalWidth = img[x].naturalWidth;
      let originalHeight = img[x].naturalHeight;
      let aspect = originalWidth / originalHeight;
      let autoHeight = maxWidth / aspect;
      canvas.height = autoHeight;

      // -- Create 2D ↓

      let ctx = canvas.getContext("2d");
      ctx.drawImage(img[x], 0, 0, maxWidth, autoHeight);
      let srcEncoded = ctx.canvas.toDataURL(img[x], "image/jpg");
      let splitEncoded = srcEncoded.split(",")[1];
      let byteCharacters = atob(splitEncoded);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let toBlob = new Blob([byteArray], {
        type: "image/jpg",
      });
      let newFile = new File([toBlob], "image", {
        type: "image/jpg",
        lastModified: Date.now(),
      });
      output.push(newFile);
    }

    if (output.length) AppendFormData(output);
  }

  // ----- Step 4 : Append Compressed Files To FormData ↓

  function AppendFormData(files) {
    const formData = new FormData();
    files.forEach((file) => formData.append("Filelist", file));
    API_PostFileSubmission(formData);
  }

  // ----- Step 5 : Submit Files ↓

  function API_PostFileSubmission(formData) {
    const URL = "/api/insert/upload/album/?";
    const QUERY = `name=${albName}&category=${category}&comment=${comment}&date=${albumDate}&type=alb&timestamp=${Timestamp()}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "POST",
      type: "JSON",
      url: ENDPOINT,
      cache: false,
      contentType: false,
      processData: false,
      onUploadProgress: setIsUploading(true),
      enctype: "multipart/form-data",
      data: formData,
    })
      .then((res) => {
        if (res.data.status) {
          HandleStatesReset();
          navigateTo("/dashboard/?ref=albums");
        }
      })
      .catch((err) => console.error(err));
  }

  function HandleFileSubmission() {
    if (
      inputFilesARR.length <= 1 ||
      !albName.length ||
      !albumDate.length ||
      !category.length
    ) {
      alert(
        `Error: * fields are required!\n- Album name\n- Category\n- Date\n- A minimum of 2 photos allow`
      );
      return false;
    } else {
      CompressFile();
    }
  }

  // -------------------------------------------
  // ----- Handle States Reset -----------------
  // -------------------------------------------

  function HandleStatesReset() {
    setInputFilesARR([]);
    setAlbName("");
    setComment("");
    getDate();
    setIsUploading(false);
  }

  function HandleFileInputDeletion(index) {
    const selectedIndex = inputFilesARR.findIndex((a) => a === index);
    const newArr = inputFilesARR.filter((a, index) => index !== selectedIndex);
    setInputFilesARR(newArr);
  }

  return (
    <Box className="AddAlb_Content_Box">
      <span className="AddAlb_Heading">Add Album</span>

      <Box className="AddAlb_Inputs_Box">
        <input
          type="text"
          value={albName}
          className="AddAlb_AlbumName"
          placeholder="Enter album name*"
          onChange={(a) => setAlbName(a.currentTarget.value)}
        ></input>

        <Box className="AddAlb_SelectOptions_Box">
          <SelectDropdown
            classname={"AddAlb_SelectDropdown"}
            onclick={() => setIsDropdownShow((show) => !show)}
          >
            <SelectInput classname={"AddAlb_Select"}>
              {category === "" ? "Category" : category} <RiArrowDropDownLine />
            </SelectInput>

            {isDropdownShow && (
              <SelectList>
                {CategoriesList.map((a, index) => {
                  return (
                    <SelectItem
                      key={index}
                      onclick={(e) => setCategory(e.target.innerText)}
                    >
                      {a}
                    </SelectItem>
                  );
                })}
              </SelectList>
            )}
          </SelectDropdown>

          <input
            type="date"
            value={albumDate}
            className="AddAlb_Date"
            placeholder="MM/DD/YYYY"
            onChange={(a) => setAlbumDate(a.currentTarget.value)}
          />
        </Box>
      </Box>

      <input
        type="file"
        ref={fileRef}
        name="input-file"
        id="_input-file"
        multiple
        accept=".png, .jpg, .jpeg"
        onChange={HandleFileInputOnChange}
        style={{ display: "none" }}
      />

      <Box
        className="AddAlb_ImgPreview_Grid"
        style={{
          display: !inputFilesARR.length && "flex",
          alignItems: !inputFilesARR.length && "center",
          justifyContent: !inputFilesARR.length && "center",
        }}
      >
        <HandlePreviewSelectedFiles />
      </Box>

      <TextareaAutoResize
        style={{
          resize: "none",
          overflowY: "auto",
          width: "100%",
          maxHeight: "200px",
          marginTop: "15px",
        }}
        value={decodeURIComponent(comment)}
        onChange={(e) => setComment(encodeURIComponent(e.currentTarget.value))}
        rows={1}
        placeholder={"Say something about this album"}
        autoFocus={true}
      />

      <Box className="AddAlb_Controls_Box">
        <span>*Min. of 2 photos</span>

        <Box className="AddAlb_Controls_btns">
          <Box className="AddTml_Controls_Box">
            <Button
              size={"Small"}
              variant={"Outlined"}
              value={"Upload"}
              onclick={() => fileRef.current.click()}
            />

            <Button
              size={"Small"}
              variant={"Filled"}
              value={"Save Album"}
              onclick={() => HandleFileSubmission()}
            />
          </Box>
        </Box>
      </Box>

      {isUploading && (
        <Modal>
          <img className="AddAlb_Loading" src={gifSpinner} alt="" />
        </Modal>
      )}
    </Box>
  );
}

export default AddAlbum;
