import React from "react";
import "./Container.css";

export function Content({ className, children }) {
  return <div className={`Content ${className}`}>{children}</div>;
}

export function Box({ style, className, children }) {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}

export function Wrapper({ style, className, children }) {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}

export function ImageWrapper({ onclick, style, className, children }) {
  return (
    <div onClick={() => onclick()} style={style} className={className}>
      {children}
    </div>
  );
}

export function Sidebar({ style, className, children }) {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}
