import React, { useRef, useEffect } from "react";

export function Textarea({
  value,
  placeholder,
  rows,
  cols,
  onChange,
  className,
  style,
}) {
  return (
    <textarea
      className={"Textarea " + className}
      value={value}
      rows={rows}
      cols={cols}
      style={style}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
    />
  );
}

export function TextareaAutoResize(props) {
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  useEffect(resizeTextArea, [props.value]);

  return (
    <textarea
      style={{
        display: "flex",
        resize: "none",
        overflowY: "hidden",
        width: "100%",
        minHeight: "auto",
        fontFamily: "inherit",
        fontSize: "0.9rem",
      }}
      ref={textAreaRef}
      value={props.value}
      onChange={(e) => props.onChange(e)}
      rows={!props.rows ? 1 : props.rows}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      className={props.className}
    />
  );
}

export const ContentEditable = (props) => {
  const elRef = useRef();

  return (
    <div
      ref={elRef}
      className={props.className}
      contentEditable={props.contentEditable}
      suppressContentEditableWarning={true}
      onInput={(e) => props.onInput(e)}
      style={{
        border: props.contentEditable && "solid 1px lightGray",
        padding: props.contentEditable && "5px",
        outline: "none",
        borderRadius: "5px",
        overflowWrap: "break-word",
        whiteSpace: "pre-wrap",
        width: "100%",
        fontSize: "0.85rem",
      }}
    >
      {decodeURIComponent(props.value)}
    </div>
  );
};
