import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/inputs/buttons/Buttons";

export function NetworkError() {
  const navigateTo = useNavigate();
  return (
    <div
      className="ErrorModal"
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        zIndex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "left",
        padding: "50px",
        background: "rgb(243, 248, 255)",
      }}
    >
      <div>
        <h1 style={{ fontSize: "2em" }}>This site can't be reached</h1>
        <p style={{ marginTop: "20px" }}>
          A network error occured while trying to reach xfacircle.app. <br />
          The connection timed out.
        </p>

        <Button
          style={{ marginTop: "20px" }}
          size={"Medium"}
          variant={"Filled"}
          value={"Try again"}
          onclick={() => navigateTo("/")}
        />
      </div>
    </div>
  );
}
