import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../home/Feed.css";
import Axios from "../../libs/axios/Axios";

// ---- Comments Section ↓
import Comments from "../../features/comments/Comments";
import { GetAuthorisedUserDetail } from "../auth/Auth";
import "../../features/actions/Actions.css";

// ----------------------

import { RiInformationLine, RiShareForwardLine } from "react-icons/ri";
import { ElapsedTime, Timestamp } from "../../utils/DateTime";
import PhotoSwiper from "../../libs/swiper/Swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import blogImage from "../blog/adventure/melbourne/images/blog_500.jpg";

import {
  Content,
  Box,
  Wrapper,
  Sidebar,
  ImageWrapper,
} from "../../components/layout/Container/Container";

import { TextWithLineBreaks } from "../../components/layout/views/Text";
import { TextWithLineBreaksReadMore } from "../../components/layout/views/Text";

const emojis = [
  { name: "Love", emoji: "💗", dec: "128151", color: "#c51104" },
  { name: "Like", emoji: "👍", dec: "128077", color: "#fbba08" },
  { name: "Bravo", emoji: "👏", dec: "128079", color: "#fbba08" },
  { name: "Haha", emoji: "😆", dec: "128518", color: "#fbba08" },
  { name: "Wow", emoji: "😲", dec: "128562", color: "#fbba08" },
];

function FeedItemsList({ arr, index, a, b, imgClick }) {
  const MemoItemList = useMemo(() => {
    return (
      <ImageWrapper
        className={`Box${index} PL-Feed_CardThumbImg_Wrapper`}
        onclick={() => imgClick()}
      >
        <LazyLoadImage
          alt="tml"
          src={b._baseURL}
          width={"100%"}
          height={"100%"}
          wrapperClassName="PL-Feed_LazyLoadWrapper" /** customised */
        />

        {a._files.length > 4 && index === 3 ? (
          <div className={`Box3 CardMorePhotos`}>+{a._files.length - 4}</div>
        ) : (
          ""
        )}
      </ImageWrapper>
    );
  }, [arr]);

  return MemoItemList;
}

function Feed({ isAuth }) {
  const userDetail = GetAuthorisedUserDetail();

  const [allPhotosARR, setAllPhotosARR] = useState([]);
  const [profileMetaARR, setProfileMetaARR] = useState([]);

  const [slideARR, setSlideARR] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [fileID, setFileID] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);

  const [toggleReactionOptions, setToggleReactionOptions] = useState(false);
  const [toggleReactedList, setToggleReactedList] = useState(false);
  const [metaId, setMetaId] = useState(null);

  const [toggleCopyLink, setToggleCopyLink] = useState(false);

  const [toggleCommentBox, setToggleCommentBox] = useState(false);
  const [postId, setPostId] = useState(null);
  const [refType, setCurrentType] = useState("");
  const [showCommentLists, setShowCommentLists] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const reactionMenuRef = useRef();

  const navigateTo = useNavigate();

  useEffect(() => {
    document.title = "Xfa Circle - Feed";
    API_GetProfileMeta();
    API_GetPostLists();
    closeReactionMenu();
  }, []);

  const closeReactionMenu = () => {
    let handleReactionMenu = (e) => {
      if (!reactionMenuRef.current.contains(e.target)) {
        setToggleReactionOptions(false);
      }
    };

    document.body.addEventListener("click", () => {
      if (!reactionMenuRef.current) {
        return false;
      } else {
        handleReactionMenu();
      }
    });
    return () => {
      document.body.removeEventListener("click", handleReactionMenu);
    };
  };

  useEffect(() => HandleSlideArrAndSelectedIndex(), [slideARR, fileID]);
  useEffect(() => {
    const minItems = 20;
    if (isVisible && allPhotosARR.length >= minItems) API_GetPostLists();
  }, [isVisible]);

  useEffect(() => {
    let timeout;
    if (toggleCopyLink) {
      timeout = setTimeout(() => {
        setToggleCopyLink(false);
      }, 350);
    }
    return () => clearTimeout(timeout);
  }, [toggleCopyLink]);

  function API_GetPostLists() {
    const limitRows = 20;
    const offSetRows = allPhotosARR.length;

    const URL = "/api/select/feed/list?";
    const QUERY = `limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        let data = res.data.result;
        if (data.length) setAllPhotosARR([...allPhotosARR, ...data]);
      })
      .catch((err) => {
        if (err) navigateTo("/error500");
        console.error(err);
      });
  }

  // ---📌Comments ↓

  const addComment = (data) => {
    const addComm = allPhotosARR.map((item) => {
      return Number(item._metaId) === Number(data[0]._postId)
        ? { ...item, _commentLists: [...data, ...item._commentLists] }
        : item;
    });
    setToggleCommentBox(false);
    setAllPhotosARR(addComm);
  };

  const editComment = (data) => {
    const updateComm = allPhotosARR.map((item) =>
      Number(item._metaId) === Number(data.postId)
        ? {
            ...item,
            _commentLists: item._commentLists.map((comment) =>
              Number(comment._commentId) === Number(data.uniqueId)
                ? {
                    ...comment,
                    _content: data.content,
                    _updatedAt: data.updatedAt,
                  }
                : comment
            ),
          }
        : item
    );
    setAllPhotosARR(updateComm);
  };

  const deleteComment = (data) => {
    const updatedData = allPhotosARR.map((item) => {
      if (Number(item._metaId) === Number(data.postId)) {
        const deleteNestedData = item._commentLists.filter(
          (nestedItem) =>
            Number(nestedItem._commentId) !== Number(data.uniqueId)
        );
        return { ...item, _commentLists: deleteNestedData };
      }
      return item;
    });

    setAllPhotosARR(updatedData);
  };

  // ----------------------------------------

  function HandleSlideArrAndSelectedIndex() {
    if (slideARR.length) {
      const index = slideARR[0]._files.findIndex((idx) => {
        return idx._fileID === fileID && idx;
      });

      setImgIndex(index);
      setToggleModal(true);
    }
  }

  function API_GetProfileMeta() {
    Axios({
      method: "GET",
      type: "json",
      url: "/api/select/profileCover",
    })
      .then((res) => {
        if (res.data.length) setProfileMetaARR(res.data);
      })
      .catch((err) => console.error(err));
  }

  // --- 📌React/Emoji ↓

  const SelectReaction = (data) => {
    const obj = Object.values(data).every((value) => !!value);
    if (!obj) console.error("Invalid input");
    if (obj) API_ManageReactionSelected(data);
  };

  function API_ManageReactionSelected(data) {
    Axios({
      method: "POST",
      type: "json",
      url: "/api/insert/reaction/admin",
      data: data,
    })
      .then((res) => {
        let data = res.data;

        if (data.status && data.action === "updated") {
          setAllPhotosARR(
            allPhotosARR.map((a) => {
              if (
                Number(a._metaId) === Number(data.metaId) &&
                a._type === data.type
              ) {
                // Update reaction array ↓

                a._reaction.map((b) => {
                  if (
                    Number(b._adminId) == Number(data.adminId) &&
                    b._type === data.type
                  ) {
                    return (
                      (b._emoji = data.emoji),
                      (b._color = data.color),
                      (b._name = data.name),
                      (b._decode = data.decode)
                    );
                  }
                });

                // Update reacted array ↓
                a._reacted.map((b) => {
                  if (
                    Number(b._adminId) == Number(data.adminId) &&
                    b._type === data.type
                  ) {
                    return (
                      (b._emoji = data.emoji),
                      (b._color = data.color),
                      (b._name = data.name),
                      (b._decode = data.decode)
                    );
                  }
                });

                // Update uniqueEmojis array ↓
                a._uniqueEmojis.map((b) => {
                  if (
                    Number(b._adminId) == Number(data.adminId) &&
                    b._type === data.type
                  ) {
                    return (
                      (b._emoji = data.emoji),
                      (b._color = data.color),
                      (b._name = data.name),
                      (b._decode = data.decode)
                    );
                  }
                });
              }
              return a;
            })
          );

          setAllPhotosARR((oldARR) =>
            oldARR.map((item) => {
              if (Number(item._metaId) === Number(data.metaId)) {
                return {
                  ...item,
                  _uniqueEmojis: item._uniqueEmojis.filter(
                    (obj, index) =>
                      item._uniqueEmojis.findIndex(
                        (item) => item._decode === obj._decode
                      ) === index
                  ),
                };
              }
              return item;
            })
          );
        }

        if (data.status && data.action === "deleted") {
          setAllPhotosARR((oldARR) =>
            oldARR.map((item) => {
              if (Number(item._metaId) === Number(data.metaId)) {
                return {
                  ...item,
                  _reaction: item._reaction.filter(
                    (b) => Number(b._adminId) !== Number(data.adminId)
                  ),
                  _reacted: item._reacted.filter(
                    (b) => Number(b._adminId) !== Number(data.adminId)
                  ),
                  _uniqueEmojis: item._uniqueEmojis.filter(
                    (b) => Number(b._adminId) !== Number(data.adminId)
                  ),
                };
              }
              return item;
            })
          );
        }

        // Insert new reaction ↓

        const insertedReactionObj = {
          _adminId: data.adminId,
          _color: data.color,
          _decode: data.decode,
          _emoji: data.emoji,
          _name: data.name,
          _metaId: data.metaId,
          _type: data.type,
          _adminName: data.adminName,
        };

        if (data.status && data.action === "inserted") {
          setAllPhotosARR(
            allPhotosARR.map((a) => {
              return Number(a._metaId) === Number(data.metaId) &&
                a._type === data.type
                ? {
                    ...a,
                    _reaction: [...a._reaction, insertedReactionObj],
                    _reacted: [...a._reacted, insertedReactionObj],
                    _uniqueEmojis: [...a._uniqueEmojis, insertedReactionObj],
                  }
                : a;
            })
          );

          // Filter duplicated objects ↓

          setAllPhotosARR((oldARR) =>
            oldARR.map((item) => {
              if (Number(item._metaId) === Number(data.metaId)) {
                return {
                  ...item,
                  _uniqueEmojis: item._uniqueEmojis.filter(
                    (obj, index) =>
                      item._uniqueEmojis.findIndex(
                        (item) => item._decode === obj._decode
                      ) === index
                  ),
                };
              }
              return item;
            })
          );
        }
      })
      .catch((err) => console.error(err));
  }

  // --- 📌Shared Url Link ↓

  function HandleUrlCopyLink(type, metaId) {
    const orgin = window.location.origin;
    const pathname = "/shared/preview";
    const query = `?type=${type}&metaId=${metaId}`;
    const url = orgin + pathname + query;
    window.navigator.clipboard.writeText(url);
  }

  // --- 📌Observer Intersection ↓

  const options = { root: null, rootMargin: "0px", threshold: 1.0 };

  useEffect(() => ObserverIntersection(), [containerRef, options]);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };
  function ObserverIntersection() {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
  }

  return (
    <Fragment>
      <Content>
        {allPhotosARR.length ? (
          <Box className="PL-Feed_Grouping">
            {/* ------------------------------------------------- */}
            {/* ----- 📌Left Sidebar --------------------------- */}
            {/* ------------------------------------------------- */}

            <Sidebar className="PL-Feed_LeftSidebar">
              <Box className="PL-Feed_Profile_Box WidgetBox">
                <Wrapper className="PL-Feed_ProfileImg_Wrapper">
                  {profileMetaARR.length ? (
                    <img src={profileMetaARR[0]._coverPhoto} alt="cover" />
                  ) : (
                    ""
                  )}
                </Wrapper>
                <Wrapper className="PL-Feed_ProfileLogo_Wrapper">
                  {profileMetaARR.length ? (
                    <img src={profileMetaARR[0]._profilePhoto} alt="profile" />
                  ) : (
                    ""
                  )}
                </Wrapper>
                <Box className="PL-Feed_ProfileMeta_Box">
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/about"}
                    className="PL-Feed_ProfileName"
                  >
                    XFA CIRCLE
                  </Link>
                  <p className="PL-Feed_ProfileAbout">
                    We love the Lord with all of our heart, soul, and mind. We
                    enjoy life to the fullest and embrace the positive at every
                    opportunity.
                  </p>
                </Box>
              </Box>

              <Box className="feed_adventure-blog_con">
                <img
                  className="feed_adventure-blog_img"
                  src={blogImage}
                  alt=""
                />
                <h2>OUR ADVENTURE BLOG</h2>
                <p>
                  We have been exploring the great outdoors for many years and
                  have had some amazing experiences along the way.
                </p>
                <button
                  onClick={() =>
                    (window.location.href = "/blog/adventure/melbourne")
                  }
                >
                  Visited Places
                </button>
              </Box>
            </Sidebar>

            {/* ------------------------------------------------- */}
            {/* ----- Main Feed --------------------------------- */}
            {/* ------------------------------------------------- */}

            <Box className="PL-Feed_Main">
              {allPhotosARR.map((a, index) => {
                return (
                  <Box key={index} className="PL-Feed_Card WidgetBox">
                    <Box className="PL-Feed_CardHeader_Box">
                      <Wrapper className="PL-Feed_CardHeaderImg_Wrapper">
                        <img src={a._adminPhoto} alt="" />
                      </Wrapper>

                      <Box className="PL-Feed_CardMeta_Box">
                        <span className="PL-Feed_CardEnteredBy">
                          {a._enteredBy}
                        </span>
                        {a._title && a._title.length && (
                          <span className="PL-Feed_CardTitle">{a._title}</span>
                        )}
                        <span className="PL-Feed_CardDateCreated">
                          <ElapsedTime previousTime={a._dateCreated} />
                        </span>
                      </Box>
                    </Box>

                    {a._comment && a._comment.length && (
                      <Wrapper className="PL-Feed_CardComment_Box">
                        <TextWithLineBreaksReadMore
                          styleReadMore={{ maxHeight: "100%" }}
                          wordCount={a._comment.length}
                          limit={300}
                        >
                          {a._comment.replace(/<br\s?\/?>/g, "\n")}
                        </TextWithLineBreaksReadMore>
                      </Wrapper>
                    )}

                    {/* ----- Feed-Items ↓ --------------------------- */}

                    <Box className="PL-Feed_CardThumb_Grid">
                      {a._files.map((b, index) => {
                        return (
                          <Fragment key={index}>
                            {/* ----- Ablum Item List ↓ */}

                            {a._type === "alb" && index <= 3 && (
                              <FeedItemsList
                                index={index}
                                a={a}
                                b={b}
                                imgClick={() => {
                                  allPhotosARR.map((c) => {
                                    if (c._metaId === a._metaId) {
                                      setSlideARR([c]);
                                      setFileID(b._fileID);
                                    }
                                  });
                                }}
                              />
                            )}

                            {/* ----- Timeline Item List ↓ */}

                            {a._type === "tml" && index <= 3 && (
                              <FeedItemsList
                                index={index}
                                a={a}
                                b={b}
                                imgClick={() => {
                                  allPhotosARR.map((c) => {
                                    if (c._metaId === a._metaId) {
                                      setSlideARR([c]);
                                      setFileID(b._fileID);
                                    }
                                  });
                                }}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </Box>

                    <Box
                      className="react-action_box"
                      style={{
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <Box
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {a._uniqueEmojis.map((emoji, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                border: "solid 2px white",
                                borderRadius: "50px",
                                fontSize: "20px",
                                width: "30px",
                                height: "30px",
                                alignItems: "center",
                                marginRight: "-10px",
                                fontWeight: "600",
                                cursor: "pointer",
                                background: "white",
                              }}
                              onClick={() => {
                                setMetaId(a._metaId);
                                setToggleReactedList((show) => !show);
                              }}
                            >
                              {emoji._emoji}
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  marginLeft: "5px",
                                }}
                              >
                                {a._reaction.length}
                              </span>
                            </div>
                          );
                        })}

                        {toggleReactedList && a._metaId === metaId && (
                          <ul
                            style={{
                              position: "absolute",
                              bottom: "40px",
                              left: "0",
                              display: "flex",
                              flexDirection: "column",
                              width: "100px",
                              padding: "5px",
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              borderRadius: "5px",
                              listStyle: "none",
                              background: "white",
                            }}
                          >
                            {a._reaction.map((a, index) => {
                              return (
                                <li
                                  key={index}
                                  style={{
                                    fontSize: "0.8rem",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "0",
                                    width: "100%",
                                    fontWeight: "500",
                                  }}
                                >
                                  {a._emoji} {a._adminName}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Box>

                      <div className="comment-actions-meta_con">
                        <a
                          onClick={() => {
                            setPostId(a._metaId);
                            setCurrentType(a._type);
                            setShowCommentLists(!showCommentLists);
                          }}
                        >
                          {a._commentLists.length <= 1
                            ? a._commentLists.length + " Comment"
                            : a._commentLists.length + " Comments"}
                        </a>
                      </div>
                    </Box>

                    {/* --- Tap to Select a Reaction ↓ */}

                    {isAuth ? (
                      <Box className="comment-actions_con">
                        <button
                          ref={reactionMenuRef}
                          className="action_button"
                          style={{
                            color: a._reacted.length
                              ? a._reacted[0]._color
                              : "gray",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setMetaId(a._metaId);
                            setToggleReactionOptions((show) => !show);
                          }}
                        >
                          {a._reacted.length ? (
                            <>
                              {a._reacted[0]._emoji} {a._reacted[0]._name}
                            </>
                          ) : (
                            "Like"
                          )}
                        </button>

                        <button
                          className="action_button"
                          onClick={(e) => {
                            setPostId(a._metaId);
                            setCurrentType(a._type);
                            setToggleCommentBox(!toggleCommentBox);
                          }}
                        >
                          Comment
                        </button>

                        <button
                          className="action_button"
                          onClick={() => {
                            setMetaId(a._metaId);
                            HandleUrlCopyLink(a._type, a._metaId);
                            setToggleCopyLink((show) => !show);
                          }}
                        >
                          <RiShareForwardLine className="react-action_icon" />{" "}
                          Share
                        </button>

                        <Box style={{ position: "relative" }}>
                          {toggleCopyLink &&
                            Number(metaId) === Number(a._metaId) && (
                              <Box
                                style={{
                                  position: "absolute",
                                  bottom: "40px",
                                  right: "1rem",
                                  width: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  color: "white",
                                  padding: "0.5rem",
                                  fontSize: "0.85rem",
                                  borderRadius: "5px",
                                  boxShadow:
                                    "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
                                  background: "#1368ce",
                                }}
                              >
                                Copied Link
                              </Box>
                            )}
                        </Box>

                        {/* --- 📌Emoji drop options ↓ */}

                        {toggleReactionOptions &&
                          Number(metaId) === Number(a._metaId) && (
                            <ul className="react-dropmenu_ul">
                              {emojis.map((emoji) => {
                                return (
                                  <li
                                    key={emoji.dec}
                                    className={"emoji_" + emoji.name}
                                    data-hover={emoji.name}
                                    onClick={() => {
                                      SelectReaction({
                                        name: emoji.name,
                                        emoji: emoji.emoji,
                                        decode: emoji.dec,
                                        color: emoji.color,
                                        postType: a._type,
                                        postId: a._metaId,
                                        createdAt: Timestamp(),
                                        updatedAt: Timestamp(),
                                      });

                                      setToggleReactionOptions((show) => !show);
                                    }}
                                  >
                                    {emoji.emoji}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                      </Box>
                    ) : null}

                    {/* ---- Comment Section ↓ */}

                    <Comments
                      refId={postId}
                      refType={refType}
                      postId={a._metaId}
                      adminId={a._adminId}
                      postType={a._type}
                      CommentLists={a._commentLists}
                      authorised={isAuth && userDetail}
                      showCommentLists={showCommentLists}
                      showCommentBox={isAuth && toggleCommentBox}
                      refreshAddFunction={addComment}
                      refreshEditFunction={editComment}
                      refreshDeleteFunction={deleteComment}
                    />
                  </Box>
                );
              })}

              <div
                ref={containerRef}
                className="intersection-observer"
                style={{ height: "50px" }}
              ></div>
            </Box>

            {/* ------------------------------------------------- */}
            {/* ----- Right Sidebar ----------------------------- */}
            {/* ------------------------------------------------- */}

            <Sidebar className="PL-Feed_RightSidebar">
              <Box className="PL-Feed_RecentPost WidgetBox">
                <span className="PL-Feed_SidebarHeading">
                  Recent Posts <RiInformationLine />
                </span>

                <ul className="PL-Feed_RecentPost_List">
                  {allPhotosARR.map((a, index) => {
                    return (
                      <React.Fragment key={index}>
                        {a._comment && index <= 4 && (
                          <li
                            onClick={() => {
                              allPhotosARR.map((c) => {
                                if (c._metaId === a._metaId) {
                                  setSlideARR([c]);
                                  setFileID(0);
                                }
                              });
                            }}
                          >
                            <TextWithLineBreaks
                              className="PL-Feed_RecentPostTitle"
                              value={
                                a._comment.length < 50
                                  ? a._comment
                                  : a._comment.substring(0, 50) + "..."
                              }
                            />
                            <span className="PL-Feed_RecentPostDateCreated">
                              <ElapsedTime previousTime={a._dateCreated} />
                            </span>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </Box>
            </Sidebar>
          </Box>
        ) : null}
      </Content>

      <PhotoSwiper
        files={slideARR}
        index={imgIndex}
        closeModal={() => setToggleModal(false)}
        showModal={toggleModal}
      />
    </Fragment>
  );
}

export default Feed;
