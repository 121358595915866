import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { RiCamera2Fill } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";

import Axios from "../../libs/axios/Axios";
import "../photos/Photos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "../../components/layout/imageList/ImageList";

import {
  Box,
  Wrapper,
  Content,
  ImageWrapper,
} from "../../components/layout/Container/Container";

import imgTimeline from "../../assets/images/timeline.jpg";
import imgAlbum from "../../assets/images/album.jpg";

import PhotoSwiper from "../../libs/swiper/Swiper";

function Photos() {
  const [latestPhotosListARR, setLatestPhotosARR] = useState([]);
  const [albumCategoriesARR, setAlbumCategoriesARR] = useState([]);

  const [timelinePhotoListARR, setTimelinePhotoListARR] = useState([]);
  const [albumListARR, setAlbumListARR] = useState([]);
  const [albumItemListARR, setAlbumPhotoListARR] = useState([]);

  const [slideARR, setSlideARR] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [metaID, setMetaID] = useState(0);
  const [fileID, setFileID] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);

  const [isFilterDropdown, setIsFilterDropdown] = useState(false);
  const [category, setCategory] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const navigateTo = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [prmREF] = [searchParams.get("ref")];
  const [TML, ALB, ALB_PHOTOS] = ["timeline", "albums", "albumPhotos"];

  useEffect(() => {
    document.title = "Xfa Circle - Photos";
    API_GetLatestPhotoList();
    API_GetAlbumListCategories();
    if (prmREF === ALB_PHOTOS) setSearchParams({ ref: "albums" });
  }, []);

  useEffect(() => {
    if (prmREF === TML) API_GetTimelineList();
    if (prmREF === ALB) API_GetAlbumList();
    if (prmREF === ALB_PHOTOS) API_GetAlbumListFilesByAlbId();
  }, [searchParams]);

  useEffect(() => {
    if (!category.length) return;
    API_GetAlbumListByCategory();
  }, [category]);

  useEffect(() => {
    if (slideARR.length) HandleSelectedFileIdIndex();
  }, [slideARR]);

  useEffect(() => {
    if (timelinePhotoListARR.length && metaID) HandleTimelinePhotoSlide();
  }, [metaID]);

  function HandleLatestUploadSlide() {
    setSlideARR([{ _files: [...latestPhotosListARR] }]);
  }

  function HandleTimelinePhotoSlide() {
    timelinePhotoListARR.map((a) => {
      if (Number(a._metaID) === Number(metaID)) setSlideARR([a]);
    });
  }

  function HandleSelectedFileIdIndex() {
    const index = slideARR[0]._files.findIndex((idx) => {
      return Number(idx._fileID) === Number(fileID) && idx;
    });

    setImgIndex(index);
    setToggleModal(true);
  }

  useEffect(() => HandleOberverIntersectionOnVisible(), [isVisible]);
  function HandleOberverIntersectionOnVisible() {
    const minItems = 20;

    if (
      isVisible &&
      prmREF === TML &&
      timelinePhotoListARR.length >= minItems
    ) {
      API_GetTimelineList();
    }

    if (
      isVisible &&
      prmREF === ALB &&
      albumListARR.length >= minItems &&
      category === ""
    ) {
      API_GetAlbumList();
    }

    if (
      isVisible &&
      prmREF === ALB &&
      albumListARR.length >= minItems &&
      category.length
    ) {
      API_GetAlbumListByCategory();
    }

    if (
      isVisible &&
      prmREF === ALB_PHOTOS &&
      albumItemListARR.length >= minItems
    ) {
      API_GetAlbumListFilesByAlbId();
    }
  }

  // ------------------------------------------------------------

  function API_GetAlbumListCategories() {
    const URL = "/api/select/album/list/categories";
    const ENDPOINT = URL;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => res.data && setAlbumCategoriesARR(res.data))
      .catch((err) => console.error(err));
  }

  // --- 📌Lastest Upload ↓

  const API_GetLatestPhotoList = () => {
    const limitRows = 20;
    const offSetRows = latestPhotosListARR.length;

    const URL = "/api/select/latest/upload?";
    const QUERY = `limitRows=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        if (res.data.length) setLatestPhotosARR(res.data);
      })
      .catch((err) => {
        if (err) navigateTo("/error500");
        console.error(err);
      });
  };

  const LatestPhotosList = useMemo(() => {
    return (
      <ImageList>
        {latestPhotosListARR.map((a, index) => {
          return (
            <ImageListItem key={index}>
              <LazyLoadImage
                alt="tml"
                src={a._baseURL}
                width={"100%"}
                height={"100%"}
                wrapperClassName="PL-Photos_LazyLoadWrapper"
                onClick={() => {
                  setFileID(a._fileID);
                  HandleLatestUploadSlide();
                }}
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  }, [latestPhotosListARR]);

  const PopulateLatestPhotosList = () => {
    return (
      <Box className="PL-Photos_LastestUpload_Box">
        <span
          style={{
            display: "block",
            fontSize: "1.2em",
            marginBottom: "20px",
          }}
        >
          Latest Photos
        </span>

        {LatestPhotosList}

        {latestPhotosListARR.length ? (
          <span
            className="PL-Photos_ViewMoreOptions"
            style={{
              display: "block",
              padding: "20px",
              width: "100%",
              marginTop: "20px",
              textAlign: "center",
              color: "lightGray",
            }}
            onClick={() => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}
          >
            Click Timeline or Albums to view more photos
          </span>
        ) : (
          ""
        )}
      </Box>
    );
  };

  // --- 📌Timeline Photo List ↓

  const API_GetTimelineList = () => {
    const limitRows = 20;
    const offSetRows = timelinePhotoListARR.length;

    const URL = "/api/select/timeline/files/all?";
    const QUERY = `type=tml&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        if (res.data.length) {
          return setTimelinePhotoListARR([
            ...timelinePhotoListARR,
            ...res.data,
          ]);
        }
      })
      .catch((err) => {
        if (err) navigateTo("/error500");
        console.error(err);
      });
  };

  const TimelineItemList = useMemo(() => {
    return (
      <ImageList>
        {timelinePhotoListARR.map((a, index) => {
          return (
            <ImageListItem key={index}>
              <LazyLoadImage
                alt="tml"
                src={a._files[0]._baseURL}
                width={"100%"}
                height={"100%"}
                wrapperClassName="PL-Photos_LazyLoadWrapper"
                onClick={() => {
                  setMetaID(a._metaID);
                  setFileID(0);
                }}
              />

              <Box
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem",
                  fontSize: "1.5rem",
                  color: "white",
                }}
              >
                {a._files.length > 1 ? (
                  <>
                    <RiCamera2Fill
                      style={{ fontSize: "2rem", fill: "white" }}
                    />
                    {a._files.length}
                  </>
                ) : (
                  ""
                )}
              </Box>
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  }, [timelinePhotoListARR]);

  const PopulateTimelinePhotoList = () => {
    return (
      <Fragment>
        <span className="alb_photo-list_pub" style={{ marginBottom: "1rem" }}>
          Latest Timeline
        </span>

        {TimelineItemList}
      </Fragment>
    );
  };

  // --- 📌Album List ↓

  const API_GetAlbumList = () => {
    const limitRows = 20;
    const offSetRows = albumListARR.length;

    const URL = "/api/select/album/list?";
    const QUERY = `type=alb&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => setAlbumListARR([...albumListARR, ...res.data]))
      .catch((err) => {
        if (err) navigateTo("/error500");
        return console.error(err);
      });
  };

  const AlbumList = useMemo(() => {
    return (
      <ImageList>
        {albumListARR.map((a, index) => {
          return (
            <ImageListItem key={index}>
              <LazyLoadImage
                alt="alb"
                src={a._baseURL}
                width={"100%"}
                height={"100%"}
                wrapperClassName="PL-Photos_LazyLoadWrapper"
                onClick={() => {
                  setSearchParams({ ref: "albumPhotos" });
                  setMetaID(a._metaID);
                }}
              />

              <ImageListItemBar
                title={
                  a._albumName.length > 20
                    ? a._albumName.substring(0, 20) + ".."
                    : a._albumName
                }
                totalItems={a._totalFiles + " items, " + a._year}
                itemBarClick={() => {
                  setSearchParams({ ref: "albumPhotos" });
                  setMetaID(a._metaID);
                }}
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  }, [albumListARR]);

  function PopulateAlbumList() {
    return (
      <Fragment>
        <Box className="PL-Photos_AlbumHeader_Box">
          <Box className="PL-Photos_AlbumTop_Box">
            <span
              className="alb_photo-list_pub"
              style={{
                marginBottom: !albumListARR.length ? "0px" : "20px",
              }}
            >
              Latest Albums
            </span>

            <span
              className="PL-Photos_Filter"
              style={{ display: "flex", alignItems: "center" }}
              onClick={(e) => setIsFilterDropdown((show) => !show)}
            >
              {category.length ? category : "Filter"}{" "}
              <BiCategory className="icon" />
            </span>
          </Box>

          {isFilterDropdown && (
            <ul
              className="PL-Photos_FilterDropmenu"
              style={{ marginBottom: isFilterDropdown ? "20px" : "0" }}
            >
              {albumCategoriesARR.map((a, index) => {
                return (
                  <li
                    key={index}
                    className="item"
                    onClick={(e) => {
                      setAlbumListARR([]);
                      setCategory(a._category);
                      setIsFilterDropdown(false);
                    }}
                  >
                    {a._category}
                  </li>
                );
              })}
            </ul>
          )}
        </Box>

        {AlbumList}
      </Fragment>
    );
  }

  function API_GetAlbumListFilesByAlbId() {
    const limitRows = 20;
    const offSetRows = albumItemListARR.length;

    const URL = "/api/select/album/list/files?";
    const QUERY = `type=alb&albumId=${metaID}&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => res.data.length && setAlbumPhotoListARR(res.data))
      .catch((err) => {
        if (err) navigateTo("/error500");
        console.error(err);
      });
  }

  const AlbumItemList = useMemo(() => {
    return (
      <ImageList>
        {albumItemListARR.map((a) => {
          return a._files.map((b, index) => {
            return (
              <ImageListItem key={index}>
                <LazyLoadImage
                  alt="alb"
                  src={b._baseURL}
                  width={"100%"}
                  height={"100%"}
                  wrapperClassName="PL-Photos_LazyLoadWrapper"
                  onClick={() => {
                    setSlideARR([...albumItemListARR]);
                    setFileID(b._fileID);
                  }}
                />
              </ImageListItem>
            );
          });
        })}
      </ImageList>
    );
  }, [albumItemListARR]);

  function PopulateAlbumListFiles() {
    return (
      <Box>
        <span
          className="alb_photo-list_pub"
          style={{ marginBottom: !albumItemListARR.length ? "0px" : "20px" }}
        >
          {albumItemListARR.length ? albumItemListARR[0]._title : ""}
        </span>

        {AlbumItemList}
      </Box>
    );
  }

  // --- 📌Filter Album List ↓

  function API_GetAlbumListByCategory() {
    const limitRows = 20;
    const offSetRows = albumListARR.length;

    const URL = "/api/select/album/list/category/query?";
    const QUERY = `type=alb&category=${category}&limit=${limitRows}&offSet=${offSetRows}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "GET",
      type: "json",
      url: ENDPOINT,
    })
      .then((res) => {
        if (res.data) {
          setAlbumListARR([...albumListARR, ...res.data]);
        }
      })
      .catch((err) => {
        if (err) navigateTo("/error500");
        console.error(err);
      });
  }

  // --- 📌Observer Intersection ↓

  const options = { root: null, rootMargin: "0px", threshold: 1.0 };

  useEffect(() => ObserverIntersection(), [containerRef, options]);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };
  function ObserverIntersection() {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
  }
  return (
    <Fragment>
      <Content>
        <Box className="PL-Photos_Grouping DESKTOP_WIDTH">
          <span className="PL-Photos_Heading">Photos</span>

          <Box className="PL-Photos_PhotoTypes_Grid">
            <Box className="PL-Photos_PhotoTypes_Card">
              <ImageWrapper
                className="PL-Photos_CardImg_Wrapper"
                onclick={() => {
                  setSearchParams({ ref: TML });
                  setAlbumListARR([]);
                }}
              >
                <img src={imgTimeline} alt="tml" />
              </ImageWrapper>

              <Wrapper className="PL-Photos_PhotoTypesMeta_Box">
                <span>Timeline</span>
              </Wrapper>
            </Box>

            <Box className="PL-Photos_PhotoTypes_Card">
              <ImageWrapper
                className="PL-Photos_CardImg_Wrapper"
                onclick={() => {
                  setSearchParams({ ref: ALB });
                  setTimelinePhotoListARR([]);
                }}
              >
                <img src={imgAlbum} alt="alb" />
              </ImageWrapper>

              <Wrapper className="PL-Photos_PhotoTypesMeta_Box">
                <span>Albums</span>
              </Wrapper>
            </Box>
          </Box>
        </Box>

        <Box className="PL-Photos_Grouping photo-listing DESKTOP_WIDTH">
          {!prmREF && <PopulateLatestPhotosList />}
          {prmREF === TML && <PopulateTimelinePhotoList />}
          {prmREF === ALB && <PopulateAlbumList />}
          {prmREF === ALB_PHOTOS && <PopulateAlbumListFiles />}

          <div
            ref={containerRef}
            className="intersection-observer"
            style={{ height: "auto" }}
          ></div>
        </Box>
      </Content>

      <PhotoSwiper
        files={
          (!prmREF && slideARR) ||
          (prmREF === TML && slideARR) ||
          (prmREF === ALB_PHOTOS && slideARR)
        }
        index={imgIndex}
        closeModal={() => setToggleModal(false)}
        showModal={toggleModal}
      />
    </Fragment>
  );
}

export default Photos;
