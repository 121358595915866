import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../libs/axios/Axios";
import "./AddTimeline.css";
import { Modal } from "../../../components/utils/modal/Modal";
import camera from "../../../assets/images/camera.png";
import gifSpinner from "../../../assets/images/spinner.gif";
import { RiCloseCircleFill } from "react-icons/ri";
import { Timestamp } from "../../../utils/DateTime";

import { Box } from "../../../components/layout/Container/Container";
import { Button } from "../../../components/inputs/buttons/Buttons";
import { TextareaAutoResize } from "../../../components/inputs/inputs/Inputs";

function AddMultiFiles() {
  const [inputFilesARR, setInputFilesARR] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [comment, setComment] = useState("");

  const navigateTo = useNavigate();
  const fileRef = useRef(null);

  // ----- Step 1 : Upload File ↓

  function HandleFileInputOnChange(e) {
    if (!e.target.value || inputFilesARR.length >= 5) return false;

    if (e.target.files) {
      let filterByImageType = Array.from(e.target.files).filter((file) => {
        let imgFormat = file.type.split("/")[1];
        if (imgFormat == "jpeg" || imgFormat == "png") {
          return file;
        }
      });

      let previewFilesARR = Array.from(filterByImageType).map((file) =>
        URL.createObjectURL(file)
      );

      // max. of 5 files allow ↓
      let outputARR = previewFilesARR.slice(0, 5 - inputFilesARR.length);

      if (outputARR.length) {
        setInputFilesARR([...inputFilesARR, ...outputARR]);
      }
    }
  }

  // ----- Step 2 : Preview Selected Files ↓

  function HandlePreviewSelectedFiles() {
    return (
      <>
        {inputFilesARR.map((file, index) => {
          return (
            <div
              key={index}
              className="AddTml_ImgPreview_Wrapper"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
            >
              <img className="AddTml_ImgSrc" src={file} alt="alb" />
              <RiCloseCircleFill
                onClick={() => HandleFileInputDeletion(file)}
              />
            </div>
          );
        })}

        {!inputFilesARR.length && (
          <div className="AddTml_ImgPreview_Wrapper">
            <img
              className="AddTml_ImgSrc"
              src={camera}
              alt="alb"
              style={{ width: !inputFilesARR.length && "128px" }}
            />
          </div>
        )}
      </>
    );
  }

  // ----- Step 3 : Compress Selected Files ↓

  function CompressFile() {
    const img = document.querySelectorAll(".AddTml_ImgSrc");
    const output = [];
    for (let x = 0; x < img.length; x++) {
      let canvas = document.createElement("canvas");
      canvas.width = 1200;
      let maxWidth = canvas.width;
      let originalWidth = img[x].naturalWidth;
      let originalHeight = img[x].naturalHeight;
      let aspect = originalWidth / originalHeight;
      let autoHeight = maxWidth / aspect;
      canvas.height = autoHeight;

      // -- Create 2D ↓

      let ctx = canvas.getContext("2d");
      ctx.drawImage(img[x], 0, 0, maxWidth, autoHeight);
      let srcEncoded = ctx.canvas.toDataURL(img[x], "image/jpg");
      let splitEncoded = srcEncoded.split(",")[1];
      let byteCharacters = atob(splitEncoded);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let toBlob = new Blob([byteArray], {
        type: "image/jpg",
      });
      let newFile = new File([toBlob], "image", {
        type: "image/jpg",
        lastModified: Date.now(),
      });
      output.push(newFile);
    }

    if (output.length) AppendFormData(output);
  }

  // ----- Step 4 : Append Compressed Files To FormData ↓

  function AppendFormData(files) {
    const formData = new FormData();
    files.forEach((file) => formData.append("Filelist", file));
    API_PostFileSubmission(formData);
  }

  // ----- Step 5 : Submit Files ↓

  function API_PostFileSubmission(formData) {
    const URL = "/api/insert/upload/timeline/?";
    const QUERY = `comment=${comment}&type=tml&timestamp=${Timestamp()}`;
    const ENDPOINT = URL + QUERY;

    Axios({
      method: "POST",
      type: "JSON",
      url: ENDPOINT,
      cache: false,
      contentType: false,
      processData: false,
      onUploadProgress: setIsUploading(true),
      enctype: "multipart/form-data",
      data: formData,
    })
      .then((res) => {
        if (res.data.status) {
          HandleStatesReset();
          navigateTo("/dashboard/?ref=timeline");
        }
      })
      .catch((err) => console.error(err));
  }

  function HandleFileSubmission() {
    if (!inputFilesARR.length || inputFilesARR.length > 5) {
      alert(
        `Error: * fields are required!\n- Add Photo\n- Maximum of 5 photos`
      );
      return false;
    } else {
      CompressFile();
    }
  }

  // -------------------------------------------
  // ----- Handle States Reset -----------------
  // -------------------------------------------

  function HandleStatesReset() {
    setInputFilesARR([]);
    setIsUploading(false);
  }

  function HandleFileInputDeletion(index) {
    const selectedIndex = inputFilesARR.findIndex((a) => a === index);
    const newArr = inputFilesARR.filter((a, index) => index !== selectedIndex);
    setInputFilesARR(newArr);
  }

  return (
    <Box className="AddTml_Content_Box">
      <span className="AddTml_Heading">Add Timeline Photo</span>

      <input
        type="file"
        ref={fileRef}
        name="input-file"
        id="_input-file"
        multiple
        accept=".png, .jpg, .jpeg"
        onChange={HandleFileInputOnChange}
        style={{ display: "none" }}
      />

      <Box
        className="AddTml_ImgPreview_Grid"
        style={{
          display: !inputFilesARR.length && "flex",
          alignItems: !inputFilesARR.length && "center",
          justifyContent: !inputFilesARR.length && "center",
        }}
      >
        <HandlePreviewSelectedFiles />
      </Box>

      <TextareaAutoResize
        style={{
          resize: "none",
          overflowY: "auto",
          width: "100%",
          maxHeight: "200px",
          marginTop: "15px",
        }}
        value={decodeURIComponent(comment)}
        onChange={(e) => setComment(encodeURIComponent(e.currentTarget.value))}
        rows={1}
        placeholder={"Say something about this photo"}
        autoFocus={true}
      />

      <Box className="AddTml_Bottom_Box">
        <span>*Max. of 5 photos</span>

        <Box className="AddTml_Controls_Box">
          <Button
            size={"Small"}
            variant={"Outlined"}
            value={"Upload"}
            onclick={() => fileRef.current.click()}
          />

          <Button
            size={"Small"}
            variant={"Filled"}
            value={"Save Photos"}
            onclick={() => HandleFileSubmission()}
          />
        </Box>
      </Box>

      {isUploading && (
        <Modal>
          <img className="AddTml_Loading" src={gifSpinner} alt="" />
        </Modal>
      )}
    </Box>
  );
}

export default AddMultiFiles;
