import React, { useState } from "react";
import Axios from "../../libs/axios/Axios";
import {
  RiLinkedinFill,
  RiInstagramLine,
  RiYoutubeLine,
  RiFacebookFill,
  RiGlobalLine,
  RiGithubLine,
  RiSnapchatLine,
} from "react-icons/ri";
import "../about/About.css";

import {
  Content,
  Box,
  Wrapper,
} from "../../components/layout/Container/Container";
import { useEffect } from "react";

function ProfileCardList({ children }) {
  return <div className="ProfileCardList">{children}</div>;
}

function ProfileCardItem({ children }) {
  return <div className="ProfileCardItem">{children}</div>;
}

function ProfileCardHeader({ color, children }) {
  return <div className={`ProfileCardHeader ${color}`}>{children}</div>;
}

function ProfileCardDetail({ img, fullname }) {
  return (
    <div className="ProfileCardDetail">
      <div className="ProfileCardImage">
        <img src={img} alt="" />
      </div>
      <span className="ProfileCardName">{fullname}</span>
    </div>
  );
}

function ProfileCardDescription({ children }) {
  return <div className="ProfileCardDescription">{children}</div>;
}

function ProfileCardContact({ children }) {
  return <div className="ProfileCardContact">{children}</div>;
}

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="description">
      {isReadMore ? text.slice(0, 130) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

function About() {
  const [profilePhotosARR, setProfilePhotosARR] = useState([]);

  useEffect(() => API_GetProfilePhotos(), []);

  function API_GetProfilePhotos() {
    Axios({
      method: "GET",
      type: "JSON",
      url: "/api/select/profilePhotos",
    })
      .then((res) => {
        if (res.data.length) setProfilePhotosARR(res.data);
      })
      .catch((err) => console.error(err));
  }

  return (
      <Content>
        <Box className="PL-About_Grouping DESKTOP_WIDTH">
          <Wrapper className="con_page-heading">
            <h1 className="PageHeading">About</h1>
          </Wrapper>

          <ProfileCardList>
            <ProfileCardItem>
              <ProfileCardHeader>
                <ProfileCardDetail
                  img={!profilePhotosARR.length ? "" : profilePhotosARR[0]}
                  fullname={"Alatina"}
                />
              </ProfileCardHeader>

              <ProfileCardDescription>
                <ReadMore>
                  Becoming a father was wonderful and our children are a
                  precious gift from God. Taking pictures of our family moments
                  or doing what we love to do brings me joy. When I'm not
                  working we will always be out exploring together or visiting
                  our families and friends. A techie at heart with years of
                  experience in finance and computer programming. I occasionally
                  taught piano, recorded and performed music for special events
                  for over 15 years.
                </ReadMore>
              </ProfileCardDescription>

              <ProfileCardContact className="ProfileCardContact">
                <RiLinkedinFill
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://au.linkedin.com/in/alatina-aiga-951067232",
                      "_blank"
                    )
                  }
                />
                <RiInstagramLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/alatinaaiga/",
                      "_blank"
                    )
                  }
                />
                <RiFacebookFill
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open("https://www.facebook.com/alti.aiga", "_blank")
                  }
                />
                <RiGlobalLine
                  className="ProfileCardIcon"
                  onClick={() => window.open("https://aaiga.me/", "_blank")}
                />

                <RiGithubLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open("https://github.com/aaiga", "_blank")
                  }
                />
              </ProfileCardContact>
            </ProfileCardItem>

            <ProfileCardItem>
              <ProfileCardHeader>
                <ProfileCardDetail
                  img={!profilePhotosARR.length ? "" : profilePhotosARR[1]}
                  fullname={"Faautu"}
                />
              </ProfileCardHeader>

              <ProfileCardDescription>
                <ReadMore>
                  My surroundings and priorities is God to be the forefront in
                  everything I do in life. I had the pleasure of working and
                  being a fulltime mother to our 3 amazing boys. It's
                  challenging but I love it and wouldn’t change it for anything.
                  I love exploring nature, spending quality time with my family,
                  cooking and reading because everyday is a gift from God.
                </ReadMore>
              </ProfileCardDescription>
              <ProfileCardContact>
                <RiFacebookFill
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/slj.aiolupotea",
                      "_blank"
                    )
                  }
                />

                <RiInstagramLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/utuaiolupotea/",
                      "_blank"
                    )
                  }
                />
              </ProfileCardContact>
            </ProfileCardItem>

            <ProfileCardItem>
              <ProfileCardHeader>
                <ProfileCardDetail
                  img={!profilePhotosARR.length ? "" : profilePhotosARR[4]}
                  fullname={"Sonix"}
                />
              </ProfileCardHeader>
              <ProfileCardDescription>
                <ReadMore>
                  I'm a full-time student and I also possess a strong passion
                  for mathematics, computer programming, music, rugby and all
                  while trying to maintain a healthy and fit lifestyle.
                </ReadMore>
              </ProfileCardDescription>
              <ProfileCardContact>
                <RiInstagramLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/sonix_aiga",
                      "_blank"
                    )
                  }
                />
                <RiGlobalLine
                  className="ProfileCardIcon"
                  onClick={() => window.open("https://saigax.me", "_blank")}
                />
                <RiGithubLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open("https://github.com/saigax07", "_blank")
                  }
                />
                <RiSnapchatLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.snapchat.com/add/sonix_aiga",
                      "_blank"
                    )
                  }
                />
                <RiYoutubeLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@codemethods",
                      "_blank"
                    )
                  }
                />
              </ProfileCardContact>
            </ProfileCardItem>

            <ProfileCardItem>
              <ProfileCardHeader>
                <ProfileCardDetail
                  img={!profilePhotosARR.length ? "" : profilePhotosARR[3]}
                  fullname={"Lenix"}
                />
              </ProfileCardHeader>
              <ProfileCardDescription>
                <ReadMore>
                  Some of my own interests and hobbies are coding which I am
                  hugely passionate about, playing sports and spending quality
                  time with my family. I am currently a full-time student and am
                  focused in my studies for this year.
                </ReadMore>
              </ProfileCardDescription>
              <ProfileCardContact>
                <RiSnapchatLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.snapchat.com/add/lenix_aiga",
                      "_blank"
                    )
                  }
                />
                <RiGithubLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open("https://github.com/laigax", "_blank")
                  }
                />
              </ProfileCardContact>
            </ProfileCardItem>

            <ProfileCardItem>
              <ProfileCardHeader>
                <ProfileCardDetail
                  img={!profilePhotosARR.length ? "" : profilePhotosARR[2]}
                  fullname={"Jerix"}
                />
              </ProfileCardHeader>
              <ProfileCardDescription>
                <ReadMore>
                  I am a very socializing and outgoing kid that loves to spend
                  quality time with my family. I also play Rugby, Soccer,
                  Basketball and love hiking. I'm passionate about coding and
                  playing the piano for entertainment.
                </ReadMore>
              </ProfileCardDescription>
              <ProfileCardContact>
                <RiYoutubeLine
                  className="ProfileCardIcon"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@xfafamilyzone4853",
                      "_blank"
                    )
                  }
                />
              </ProfileCardContact>
            </ProfileCardItem>
          </ProfileCardList>
        </Box>
      </Content>
  );
}

export default About;
