import React, { useEffect, useState } from "react";
import Axios from "../../libs/axios/Axios";

export function CheckLoginStatus() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => HandleCheckLoginStatus(), []);

  const HandleCheckLoginStatus = () => {
    Axios.get("/api/user/authentication")
      .then((res) => setIsAuthenticated(res.data.status))
      .catch(() => setIsAuthenticated(false));
  };
  return isAuthenticated;
}

export function GetAuthorisedUserDetail() {
  const [userDetail, setUserDetail] = useState(null);
  useEffect(() => HandleAuthorisedUserDetail(), []);

  const HandleAuthorisedUserDetail = () => {
    Axios.get("/api/user/authorised-user-detail")
      .then((res) => setUserDetail(res.data))
      .catch(() => setUserDetail(false));
  };
  return userDetail;
}
